import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  styled,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { ButtonText, H5, Tiny } from "components/Typography";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { useRef, useState } from "react";
import { Minus, Plus } from "react-feather";
import { useSelector } from "react-redux";
import { events, stream } from 'fetch-event-stream';
import { Button } from "react-scroll";
import toast, { Toaster } from "react-hot-toast";
import AppCheckBox from "components/AppCheckBox";

export default function Curriculum({
  index,
  classData,
  video,
  closeModal
}) {
  const token = useSelector((state) => state.Auth.token);
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [video_url, setVideoUrl] = useState(video.video_id);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isLargeScreen = window.innerWidth > 1720;

  const UploadButton = styled(Box)(({ theme }) => ({
    width: "fit-content",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "25px",
    paddingRight: "25px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: theme.palette.borderColor,
    backgroundColor: theme.palette.primary.grey700,
  }));

  const [title, setTitle] = useState(video.name);
  const [selectedCurriculum, setSelectedCurriculum] = useState(classData.curriculum_data.filter((item) => item.id === video.curriculum_id)?.[0]?.name || "");
  const [fileName, setFileName] = useState(video.original_name);
  const [folderName, setFolderName] = useState(video.folder_name);

  const [videoFile, setVideoFile] = useState(video.thumbnail);
  const [thumbnail, setThumbnail] = useState(video.thumbnail);
  const [runningTime, setRunningTime] = useState(video.running_time);
  const [publicVideo, setPublicVideo] = useState(Boolean(video?.public));


  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleFileInputChange = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    setVideoFile(URL.createObjectURL(file));

    // Create a video element to capture a frame for the thumbnail
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    video.addEventListener("loadeddata", () => {
      video.currentTime = 0;
    });

    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      setThumbnail(canvas.toDataURL());
      const dataURLtoFile = (dataurl, fileName) => {

        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
      }
      const formData = new FormData();
      formData.append('file', dataURLtoFile(canvas.toDataURL(), "thumbnail.png"));
      axios.post(process.env.REACT_APP_API_URI+'/v4/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      }).then((res) => {
        const url = res.data.data.file_url
        setThumbnail(url)
      })
    });

    const formData = new FormData();
    formData.append('file', file);
    const xhr = new XMLHttpRequest();
    toast.success("파일 업로드 시작");
    xhr.open('POST', process.env.REACT_APP_API_URI+'/v4/file/upload/video', true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 3) {
        const newData = xhr.response.substr(xhr.seenBytes);
        const dataArray = newData.split('}{');
        for (let i = 0; i < dataArray.length; i++) {
          let data = dataArray[i];
          if (dataArray.length === 1) {
            data = JSON.parse(newData)
          } else {
            if (i === 0) {
              data += '}';
            } else if (i === dataArray.length - 1) {
              data = '{' + data;
            } else {
              data = '{' + data + '}';
            }
            data = JSON.parse(data);
          }
          if (data.message === "파일 업로드에 성공했습니다.") {
            setFileName("파일 업로드에 성공했습니다.");
            toast.success("파일 업로드에 성공했습니다.");
          } else if (data.message === "파일 변환 중") {
            setFileName("파일 변환 중 " + Math.min(Number(data.data.progress), 100) + "%");
          } else if (data.message === "파일 변환 완료") {
            toast.success("파일 변환 완료");
            setUploading(false)
            setFileName(data.data.name)
            setVideoUrl(data.data.file_url)
            setRunningTime(data.data.duration)
            setFolderName(data.data.folder_name)
          }
        }

        xhr.seenBytes = xhr.responseText.length;
      }
    };
    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        console.log(`Upload progress: ${progress.toFixed(2)}%`);
        setFileName("파일 업로드 중 " + progress.toFixed(2) + "%");
      }
    };

    xhr.send(formData);
  };
  const handleUploadVideo = () => {
    if (uploading) {
      toast.error("파일 업로드 중입니다. 잠시만 기다려 주세요");
      return;
    }
    if (title === "") {
      toast.error("제목을 입력해 주세요");
      return;
    }
    if (videoFile === null) {
      toast.error("영상을 업로드해 주세요");
      return;
    }
    if (thumbnail === null) {
      toast.error("썸네일을 업로드해 주세요");
      return;
    }
    if (!video_url) {
      toast.error("영상이 아직 업로드되지 않았습니다.");
      return;
    }
    console.log(selectedCurriculum)
    console.log(classData.curriculum_data.find((item) => item.name === selectedCurriculum).id)
    let curriculumId = selectedCurriculum === "" ? classData.curriculum_data[0].id : classData.curriculum_data.find((item) => item.name === selectedCurriculum).id;
    const data = {
      name: title,
      video: video_url,
      thumbnail: thumbnail,
      running_time: runningTime,
      file_name: fileName,
      curriculum_id: curriculumId,
      folder_name: folderName,
      public_video: publicVideo
    }
    axios.patch(process.env.REACT_APP_API_URI+'/v4/course/video/' + video.id, data, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((res) => {
      if (res.data.message === "강의 수정에 성공했습니다.") {
        toast.success("강의 수정에 성공했습니다.");
        closeModal()
      } else {
        toast.error("강의 수정에 실패했습니다.");
      }
    })
  }
  const handleFileThumbnailInputChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    axios.post(process.env.REACT_APP_API_URI+'/v4/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      }
    }).then((res) => {
      const url = res.data.data.file_url
      setThumbnail(url)
    })
  }
  return (
    <div style={{
      padding: 10
    }}>

      {/* <FlexRowAlign
        mb={3}
        gap={2}
        sx={{ justifyContent: "flex-start", alignItems: "center" }}
      >
        <H5 mr={1}>{index + 1}강</H5>
      </FlexRowAlign> */}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid item lg={6} xs={12}>
            <ButtonText mb={1}>제목</ButtonText>
            <AppTextField
              fullWidth
              name="title"
              placeholder="제목을 입력해 주세요"
              value={title}
              onChange={handleTitleChange}
              sx={{
                backgroundColor: "#ffffff",
                "& .MuiOutlinedInput-input::placeholder": {
                  fontWeight: 300,
                  fontSize: 15.5,
                  color: "#B5B7C0",
                },
              }}
              inputProps={{ style: { height: 18 } }}
            />
          </Grid>
        </Grid>

        <Grid item sm={6} xs={12}>
          <ButtonText mb={1}>커리큘럼 선택</ButtonText>
          <MySelect
            items={classData?.curriculum_data?.map((item) => item.name) || []}
            defaultSelectValue={selectedCurriculum}
            width={"100%"}
            height={"50px"}
            onChange={(e) => setSelectedCurriculum(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <ButtonText mb={1}>영상 업로드</ButtonText>
          <Box
            mb={2}
            sx={{
              width: "188px",
              height: "141px",
              background: thumbnail
                ? `url(${thumbnail}) no-repeat center/cover`
                : theme.palette.borderColor,
              borderRadius: 3,
            }}
          />

          <Box
            px={1}
            gap={0}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid",
              borderColor: theme.palette.borderColor,
              width: isMobile ? "100%" : "fit-content",
              backgroundColor: "#ffffff",
              minWidth: isMobile ? 0 : 400,
              height: "50px",
              borderRadius: 2,
            }}
          >
            <Tiny
              sx={{
                fontWeight: 300,
                color: theme.palette.primary.gray400,
              }}
            >
              {fileName?.length > 0
                ? fileName
                : "선택된 파일 없음"}
            </Tiny>
            <UploadButton>
              <label htmlFor="upload-btn">
                <input
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  accept="video/*" // Make sure this line is updated
                  id="upload-btn"
                  type="file"
                  style={{ display: "none" }}
                  disabled={uploading}
                />
                <IconButton component="span" disableRipple>
                  <Tiny
                    sx={{
                      fontWeight: 300,
                      color: theme.palette.primary.dddgray400,
                    }}
                  >
                    파일 선택
                  </Tiny>
                </IconButton>
              </label>
            </UploadButton>
            <UploadButton>
              <label htmlFor="upload-btn2">
                <input
                  onChange={handleFileThumbnailInputChange}
                  accept="image/*" // Make sure this line is updated
                  id="upload-btn2"
                  type="file"
                  style={{ display: "none" }}
                />
                <IconButton component="span" disableRipple>
                  <Tiny
                    sx={{
                      fontWeight: 300,
                      color: theme.palette.primary.dddgray400,
                    }}
                  >
                    영상 썸네일 업로드
                  </Tiny>
                </IconButton>
              </label>
            </UploadButton>
          </Box>
        </Grid>

        <Grid item sm={12} xs={12}>
          <ButtonText mb={1}>강의 영상 전체 공개여부</ButtonText>
          <AppCheckBox checked={publicVideo} onChange={(e) => setPublicVideo(e.target.checked)} />
        </Grid>



        <Grid item sm={6} xs={12}>
          <Button
            style={{
              // backgroundColor: "#FFD600",
              color: "#000000",
              borderRadius: "8px",
              width: "100%",
              height: "50px",
              fontSize: "14px",
              fontWeight: "300",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              handleUploadVideo()
            }}
          >
            강의 수정 완료
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

function MySelect({
  items,
  width,
  height,
  backgroundColor,
  onChange,
  defaultSelectValue
}) {
  const theme = useTheme();
  const [selectValue, setSelectValue] = useState(defaultSelectValue ? defaultSelectValue : items[items.length - 1]);

  const handleChange = (event) => {
    setSelectValue(event.target.value);
    onChange(event)
  };

  return (
    <>
      <FlexRowAlign gap={2}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectValue}
          // placeholder="제목 + 내용"
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "0",
                margin: "0",
              },
            },
          }}
          input={
            <InputBase
              sx={{
                height: height ? height : 40,
                width: width ? width : 110,
                fontSize: 14,
                textAlign: "left",
                paddingLeft: "10px !important",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "8px",
                color: "text.primary",
                backgroundColor: backgroundColor ? backgroundColor : "#ffffff",
                marginBottom: 0,
                border: "1px solid #E1E1E1",
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                },
                "& > .MuiSelect-select": {
                  paddingRight: "0 !important",
                },
              }}
            />
          }
        >
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                sx={{
                  height: height ? height : 40,
                  width: width ? width : 110,
                  fontSize: 14,
                  fontWeight: 300,
                  border: "1px solid #E1E1E1",
                }}
                value={item}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FlexRowAlign>
    </>
  );
}
