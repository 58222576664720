const MenuData = [
  {
    title: "메뉴",
    sectionTitle: true,
  },
  {
    title: "경정청구",
    icon: "/static/sidemenu/paper.png",
    selectedIcon: "/static/sidemenu/paper-selected.png",
    path: "/manage/table",
  },
  {
    title: "메인배너 수정",
    icon: "/static/sidemenu/paper.png",
    selectedIcon: "/static/sidemenu/paper-selected.png",
    path: "/manage/banner-editor",
  },
  {
    title: "사용자 관리",
    icon: "/static/sidemenu/paper.png",
    selectedIcon: "/static/sidemenu/paper-selected.png",
    path: "/manage/user-list",
  },
];

export { MenuData };
