import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Scrollbar from "components/ScrollBar";
import { ButtonText, H5, H6, Tiny } from "components/Typography";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import LayoutDrawer from "layouts/layout-parts/LayoutDrawer";
import SurveyModal from "page/modal/survey-modal2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import FlexBox from "../../components/flexbox/FlexBox";
import { sideMenuList1 } from "../layout-parts/navList";
import AccordionMenu from "./AccordionMenu";

import img1 from "assets/img1.png";
import book from "assets/sidemenu/book.png";
import logo from "assets/sugar_logo.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CompleteModal from "page/modal/alerts/SuccessModal";
import { Logout } from "reducer/authReducer";
import { accountPermissionCompare } from "utils/compareAccount";
import WarningModal from "page/modal/alerts/WarningModal";
import { useRef } from "react";

let width = 230;
// custom styled components
const SidebarWrapper = styled(Box)(({ theme, show }) => ({
  left: show ? 0 : -230,
  width: width,
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: theme.zIndex.drawer,
  backgroundColor: theme.palette.background.paper,
}));
const StyledLogo = styled(Box)(() => ({
  marginBottom: "1rem",
  marginLeft: "-10px",
}));
const InvisibleTrigger = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  top: 0,
  width: "10px",
  height: "100%",
  zIndex: theme.zIndex.drawer + 1,
}));
const StyledListItemButton = styled(ListItemButton)(({ active, theme }) => ({
  padding: "12px 24px",
  marginBottom: 8,
  // color: active ? "#ffffff" : "inherit",
  // backgroundColor: active ? theme.palette.primary.darkBlue : "#ffffff",
  // borderRadius: 10,
  // "&:hover": {
  //   backgroundColor: active ? theme.palette.primary.darkBlue : "#ffffff",
  // },
  // "& .MuiSvgIcon-root": {
  //   color: active ? "#ffffff" : theme.palette.primary.drakBlue,
  // },
}));

const SubMenuItem = styled(FlexBox)(({ theme, active }) => ({
  cursor: "pointer",
  overflow: "hidden",
  alignItems: "center",
  position: "relative",
  padding: "0.6rem 1rem",
  borderRadius: 10,

  "& div": {
    backgroundColor: active
      ? theme.palette.primary.lightBlueBackground
      : theme.palette.grey[600],
  },
  "& small": {
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.lightBlueBackground,
  },
  "&::before": {
    left: 0,
    width: 2,
    content: '""',
    height: "100%",
    borderRadius: 5,
    position: "absolute",
    opacity: active ? 1 : 0,
    transition: "opacity 0.3s ease",
    backgroundColor: theme.palette.primary.lightBlueBackground,
  },
})); // root component

const DashboardSidebar = ({ showSideBar, setShowSideBar, showMobileSideBar, closeMobileSideBar }) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.Auth.account);
  const sidebar = useSelector((state) => state.Sidebar.sidebar);
  const sidebar2 = useSelector((state) => state.Sidebar.sidebar2);
  const setting = useSelector((state) => state.Sidebar.setting);
  const [logout, setLogout] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState("");
  const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200)); // active accordion
  const [saasMenuOpen, setSaasMenuOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningTitle, setWarningTitle] = useState("");
  const [warningDescription, setWarningDescription] = useState("");
  const [selectedTab, setSelectedTab] = useState("클래스");
  const [sideMenuData, setSideMenuData] = useState(sidebar);

  const saasMenuItems = [
    { name: "Sub Menu 1", path: "/saas/submenu-item-1" },
    { name: "Sub Menu 2", path: "/saas/submenu-item-2" },
  ];

  const handleSaasMenuClick = (path) => {
    setSaasMenuOpen((prevOpen) => !prevOpen);
    if (path) navigate(path);
  };

  const handleSaasArrowClick = () => {
    setSaasMenuOpen((prevOpen) => !prevOpen);
  };

  const activeAccordion = () => {
    sideMenuList1.forEach((list) => {
      if (list.children && list.children.length > 0) {
        const findItem = list.children.find((item) => item.path === pathname);
        if (findItem) setExpanded(list.title);
      }
    });
  };

  useEffect(activeAccordion, [pathname]);

  const handleAccordionChange = (panel) => (_, expand) => {
    setExpanded(expand ? panel : false);
  };

  const handleSubMenuItem = (path) => {
    if (path) navigate(path);
  };

  useEffect(() => {
    const isInCommunityTab = sidebar2?.some(
      (item) =>
        item.path === pathname ||
        (item.children &&
          item.children.some((child) => child.path === pathname))
    );
    const isInClassTab = sidebar?.some(
      (item) =>
        item.path === pathname ||
        (item.children &&
          item.children.some((child) => child.path === pathname))
    );

    if (isInCommunityTab) {
      setSelectedTab("커뮤니티");
      setSideMenuData(sidebar2);
    } else if (isInClassTab) {
      setSelectedTab("클래스");
      setSideMenuData(sidebar);
    }
  }, [pathname]);
  const handleSideMenuData = (tab) => {
    setSelectedTab(tab);

    if (tab === "클래스") {
      setSideMenuData(sidebar);
    } else {
      setSideMenuData(sidebar2);
    }
  };
  const activeRoute = (path) => (path === pathname ? 1 : 0); // common side bar content
  function isPathnameMatch(obj, pathname) {
    if (obj.path == "/bizanno/bizinfo" && pathname.includes("/bizdetail/bizinfo")) {
      return true;
    } else if (obj.path == "/bizanno/kstartup" && pathname.includes("/bizdetail/kstartup")) {
      return true;
    }
    if (obj.path && obj.path === pathname) {
      return true;
    }
    if (obj.children) {
      for (let i = 0; i < obj.children.length; i++) {
        if (obj.children[i].path && obj.children[i].path === pathname) {
          return true;
        }
      }
    }
    return false;
  }
  function isPathnameMatchSub(obj, pathname) {
    if (obj.path == "/bizanno/bizinfo" && pathname.includes("/bizdetail/bizinfo")) {
      return true;
    } else if (obj.path == "/bizanno/kstartup" && pathname.includes("/bizdetail/kstartup")) {
      return true;
    }
    if (obj.path && obj.path === pathname) {
      return true;
    }
    return false;
  }
  
  // Show Sidebar on hover starts
  const [isHovering, setIsHovering] = useState(false);
  const sidebarVisible = useRef(false);
  const sidebarRef = useRef(null);
  
  const isInsurance = window.location.hostname == 'xn--3j1bu51b3tg.com' || window.location.hostname == 'sugar.ceo' ? true : false
  const handleMouseEnter = () => {
    if (!downMd) {
      if (!isInsurance) {
        setIsHovering(true);
      }
      // setShowSideBar(true);
    }
  };

  const handleMouseLeave = () => {
    if (!downMd) {
      if (!isInsurance) {
        setIsHovering(false);
      }
      // setShowSideBar(false);
    }
  };
  const sideBarContent = (
    <Scrollbar autoHide clickOnTrack={false}>
      <CompleteModal showModal={logout} setShowModal={setLogout} onAction={() => {
        dispatch(Logout())
        setTimeout(() => {
          window.location.href = '/'
        }, 10)
      }} title={"오늘도 감사합니다 :)"} description={""} />
      <List
        sx={{
          height: "100%",
        }}
      >
        <WarningModal showModal={showWarningModal} setShowModal={setShowWarningModal} title={warningTitle} description={warningDescription} />

        <SurveyModal open={showSurveyModal} setShowModal={setShowSurveyModal} />

        {/* LOGO */}
      <FlexBox
        ml={1.5}
        mt={1.4}
        justifyContent={"space-between"}
        flexDirection={"column"}
      >
        <StyledLogo>
          <NavLink to={`/dashboard`}>
            <img
              width={70}
              src={setting?.logo}
              alt={"logo"}
              style={{
                cursor: "pointer",
                marginLeft: "2px",
                height: "25px",
                marginBottom: "0px",
              }}
            />
          </NavLink>
        </StyledLogo>
      </FlexBox>

        
        <Box mb={2}>
          {account && (<H5
            fontWeight={600}
            onClick={() => {
              navigate('/settings/profile')
            }}
            ml={1.8}
            mr={1.5}
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
              background: theme.palette.gradient.purpletoblue,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
            }}
          >
            마이페이지
          </H5>)}
          {account && (<H5
            fontWeight={600}
            onClick={() => {
              if (account) {
                setLogout(true)
              }
            }}
            mr={1.5}
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
              background: theme.palette.gradient.purpletoblue,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
            }}
          >
            로그아웃
          </H5>)}
        </Box>
        <Box
        sx={{
          px: 2,
          py: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          p={0}
          container
          sx={{ backgroundColor: "#F0F0F0", borderRadius: 100 }}
        >
          <Grid
            item
            xs={6}
            py={0.8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background:
                selectedTab === "클래스"
                  ? theme.palette.gradient.purpletoblue
                  : "none",

              borderRadius: 100,
              cursor: "pointer",
            }}
            onClick={() => handleSideMenuData("클래스")}
          >
            <Tiny
              sx={{
                color:
                  selectedTab === "클래스"
                    ? theme.palette.primary.white
                    : "#808187",
                fontWeight: selectedTab === "클래스" ? 600 : 400,
              }}
            >
              클래스
            </Tiny>
          </Grid>
          <Grid
            item
            xs={6}
            py={0.8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background:
                selectedTab === "커뮤니티"
                  ? theme.palette.gradient.purpletoblue
                  : "none",
              borderRadius: 100,
              cursor: "pointer",
            }}
            onClick={() => handleSideMenuData("커뮤니티")}
          >
            <Tiny
              sx={{
                color:
                  selectedTab === "커뮤니티"
                    ? theme.palette.primary.white
                    : "#808187",
                fontWeight: selectedTab === "커뮤니티" ? 600 : 400,
              }}
            >
              커뮤니티
            </Tiny>
          </Grid>
        </Grid>
      </Box>
        {(selectedTab == "커뮤니티" ? sidebar2 : sidebar)?.map((item, index) => {
          {
            /* 드롭다운 메뉴 */
          }
          if (item.children && item.children.length > 0) {
            if (item.login) {
              if (!account) {
                return null;
              }
            }
            if (item.type) {
              if (!accountPermissionCompare(account?.type, item.type)) {
                if (item?.more_type) {
                  if (!item?.more_type?.includes(account?.type)) {
                    return null;
                  }
                } else {
                  return null;
                }
              }
            }
            if (item.partner_type) {
              if (!item.partner_type.includes(account.partner_type)) {
                return null;
              }
            }
            if (item.cretop) {
              if (account.cretop != 1) {
                return null;
              }
            }
            return (
              <AccordionMenu
                key={index}
                title={item.title}
                path={item.path}
                expandedItem={expanded}
                handleChange={handleAccordionChange}
                active={isPathnameMatch(item, pathname)}
                open={item.expanded}
                accordionHeader={
                  <FlexBox alignItems="center" sx={{ width: "100%" }}>
                    <ListItemButton
                      key={index}
                      onClick={() => {
                        if (item.need_login) {
                          if (!account) {
                            setShowLoginModal(true);
                            return;
                          }
                        }
                        if (item.need_type) {
                          if (!accountPermissionCompare(account?.type, item.need_type)) {
                            setWarningTitle("권한이 없습니다.")
                            setWarningDescription("권한이 없습니다.")
                            setTimeout(() => {
                              setShowWarningModal(true)
                            },10)
                            return;
                          }
                        }

                        handleSubMenuItem(item.path);
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",

                        "&:hover": {
                          backgroundColor: "initial",
                        },
                      }}
                    >
                      <FlexRowAlign>
                        <img
                          src={
                            true
                              ? item.selectedIcon
                              : item.icon
                          }
                          alt="check"
                          style={{
                            width: "24px",
                            height: "24px",
                            objectFit: "fill",
                            borderRadius: 10,
                          }}
                        />
                        <H6
                          lineHeight={1}
                          fontWeight={700}
                          fontSize={Number(setting?.sidebar?.fontsize?.replace('px', '')) || 15.5}
                          ml={1}
                          sx={{
                            color:
                            true
                                ? theme.palette.primary.darkBlue
                                : "inherit",
                          }}
                        >
                          {t(item.title)}
                        </H6>
                      </FlexRowAlign>

                      <FlexBox
                        sx={{
                          width: "19px",
                          height: "19px",
                          borderRadius: 100,
                          backgroundColor: "#EDEDED",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ButtonText
                          lineHeight={1}
                          fontWeight={500}
                          sx={{
                            background: theme.palette.gradient.purpletoblue,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        >
                          {item.children.length}
                        </ButtonText>
                      </FlexBox>
                    </ListItemButton>
                  </FlexBox>
                }
              >
                {item.children.map((menuItem, key) => {
                  if (!menuItem.subChildren) {
                    
                    if (menuItem.login) {
                      if (!account) {
                        return null;
                      }
                    }
                    if (menuItem.type) {
                      if (!accountPermissionCompare(account?.type, menuItem.type)) {
                        if (menuItem?.more_type) {
                          if (!menuItem?.more_type?.includes(account?.type)) {
                            return null;
                          }
                        } else {
                          return null;
                        }
                      }
                    }
                    if (menuItem.partner_type) {
                      if (!menuItem.partner_type.includes(account.partner_type)) {
                        return null;
                      }
                    }
                    if (menuItem.cretop) {
                      if (account.cretop != 1) {
                        return null;
                      }
                    }
                    return (
                      <SubMenuItem
                        key={key}
                        active={isPathnameMatchSub(menuItem, pathname)}
                        onClick={() => {
                          let item = menuItem;
                          
                        if (item.need_login) {
                          if (!account) {
                            setShowLoginModal(true);
                            return;
                          }
                        }
                        if (item.need_type) {
                          if (!accountPermissionCompare(account?.type, item.need_type)) {
                            setWarningTitle("권한이 없습니다.")
                            setWarningDescription("권한이 없습니다.")
                            setTimeout(() => {
                              setShowWarningModal(true)
                            },10)
                            return;
                          }
                        }
                          handleSubMenuItem(menuItem.path)}}
                      >
                        <H6
                          sx={{
                            color: isPathnameMatchSub(menuItem, pathname)
                              ? theme.palette.primary.darkBlue
                              : theme.palette.primary.dark,
                          }}
                          fontSize={Number(setting?.sidebar?.fontsize?.replace('px', '')) || 15.5}
                        >
                          {t(menuItem.name)}
                        </H6>
                      </SubMenuItem>
                    );
                  }
                })}
              </AccordionMenu>
            );
          }

          if (item.title === "divider") {
            return <Divider sx={{ borderColor: "#E1E1E1", my: 2 }} />;
          }
          
          if (item.login) {
            if (!account) {
              return null;
            }
          }
          if (item.type) {
            if (!accountPermissionCompare(account?.type, item.type)) {
              return null;
            }
          }
          if (item.partner_type) {
            if (!item.partner_type.includes(account.partner_type)) {
              return null;
            }
          }
          if (item.cretop) {
            if (account.cretop != 1) {
              return null;
            }
          }
          // 일반 메뉴
          return (
            <StyledListItemButton
              key={index}
              disableRipple
              active={true}
              onClick={() => {
                setExpanded(false);
                
                if (item.need_login) {
                  if (!account) {
                    setShowLoginModal(true);
                    return;
                  }
                }
                if (item.need_type) {
                  if (!accountPermissionCompare(account?.type, item.need_type)) {
                    setWarningTitle("권한이 없습니다.")
                    setWarningDescription("권한이 없습니다.")
                    setTimeout(() => {
                      setShowWarningModal(true)
                    },10)
                    return;
                  }
                }
                handleSubMenuItem(item.path);
              }}
            >
              {isPathnameMatch(item, pathname) && (
                <Box
                  sx={{
                    width: "6px",
                    height: "24px",
                    background: theme.palette.gradient.purpletobluetobottom,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    position: "absolute",
                    left: 0,
                  }}
                />
              )}

              <img
                src={true ? item.selectedIcon : item.icon}
                alt="check"
                style={{
                  width: "24px",
                  height: "24px",
                  objectFit: "fill",
                  borderRadius: 10,
                }}
              />
              <H6
                ml={1}
                lineHeight={1}
                fontWeight={700}
                fontSize={Number(setting?.sidebar?.fontsize?.replace('px', '')) || 15.5}
                sx={{
                  color:
                  true
                      ? theme.palette.primary.darkBlue
                      : "inherit",
                }}
              >
                {t(item.title)}
              </H6>
            </StyledListItemButton>
          );
        })}
      </List>
    </Scrollbar>
  ); // for mobile device

  if (downMd) {
    return (
      <LayoutDrawer open={showMobileSideBar} onClose={closeMobileSideBar}>
        {sideBarContent}
      </LayoutDrawer>
    );
  }

  return (
    <>
      <InvisibleTrigger onMouseEnter={handleMouseEnter} />
      <SidebarWrapper
        show={showSideBar || isHovering ? 1 : 0}
        ref={sidebarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {sideBarContent}
      </SidebarWrapper>
    </>
  );
};

export default DashboardSidebar;
