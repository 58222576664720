import { DndContext, useDraggable } from "@dnd-kit/core";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  IconButton,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
  Divider,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import avatar5 from "assets/avatar-5.png";
import FlexBox from "components/flexbox/FlexBox";
import { H2, H3, H5, H6 } from "components/Typography";
import {
  AlignLeft,
  Bell,
  ChevronRight,
  Search,
  Settings,
  X,
} from "lucide-react";
// import LoginModal from "pages/login-modal";
// import SignupModal from "pages/signup-modal";
import { useContext, useState, useEffect, useRef } from "react";
import { Menu } from "react-feather";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import GreySearchBar from "./GreySearchBar";
import AlarmWindow from "components/AlarmWindow";
import WarningModal from "components/modal/alerts/WarningModal";
import WarningModal2 from "page/modal/alerts/WarningModal";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Request from "utils/request";
import { setAccountType, setSetting, setSidebar, setSidebar2, setSidebarDump } from "reducer/sidebarReducer";
import { Logout, setLogin } from "reducer/authReducer";
import { accountPermissionCompare } from "utils/compareAccount";
import LoginModalSimple from "page/modal/login-simple";
import LoginModal from "page/modal/login-modal";
import CompleteModal from "page/modal/alerts/SuccessModal";
import kakao from "assets/fab-kakao.png";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import { ErrorBoundary } from 'react-error-boundary'
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import IconEditModal from "components/modal/iconEditorModal";
import FixHeaderModal from "components/modal/FixHeaderModal";
import WarningModalV2 from "page/modal/warning-modal-v2";

// 세번째 submenu가 열리는 route 들

// /naver/coursemain
// /naver/report-creator
// /naver/forumlist
// /naver/forumlist
// /naver/wrtn
// /naver/community
// /naver/workschedule
// /dashboard/Excel2
// /dashboard/data-table-v2
// /dashboard/apimanagement
// /dashboard/bannereditor
// /dashboard/menueditor

// ------------------------------------------------
// custom styled components
const StyledToolBar = styled(Toolbar)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const PanelContainer = styled(Box)({
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  backgroundColor: "#fff",
  paddingTop: "8px",
  paddingBottom: "24px",
  borderRadius: "0px",
  zIndex: 1202,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Row = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  maxWidth: "1200px",
  width: "1200px",
});

const Column = styled(Box)(({ theme, isFirst }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 16,
  paddingBottom: 16,
  borderRight: isFirst ? "none" : "1px solid #E1E1E1",
  background: isFirst ? "#EFF3FF" : "#FFFFFF",
  borderRadius: isFirst ? "10px" : "0px",
}));

const ColumnItem = styled(Box)(({ theme, isFirstColumn }) => ({
  fontSize: 15,
  fontWeight: 400,
  color: theme.palette.primary.dark,
  padding: "8px 16px",
  borderRadius: "10px",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: isFirstColumn ? "#D3DCFC" : theme.palette.primary.grey700,
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9,
}));

const ThirdLevelRow = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  backgroundColor: "#fff",
  zIndex: 0,
  display: "flex",
  justifyContent: "center",
  padding: "4px 0 4px 0",
  marginTop: "-8px",
  borderTop: "1px solid #e1e1e1",
  borderBottom: "1px solid #e1e1e1",
  overflowX: "auto",
  WebkitOverflowScrolling: "touch",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const ThirdLevelMenuItem = styled(Box)(({ theme }) => ({
  padding: "8px 16px",
  cursor: "pointer",
  whiteSpace: "nowrap",
  borderRadius: "10px",
  fontWeight: 500,
  fontSize: "0.875rem",
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.darkViolet,
  },
}));

const DashboardHeaderNew = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.Auth.token);
  const account = useSelector((state) => state.Auth.account);
  const setting = useSelector((state) => state.Sidebar.setting);
  const [showIconEdit, setShowIconEdit] = useState(false);

  const [showPanel, setShowPanel] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [openSearchBar, setSearchBar] = useState(false);
  const [openEditor, setEditorOpen] = useState(false);
  const [editorData, setEditorData] = useState(null);

  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);

  useEffect(() => {
    const request = new Request(token)
    request.get(process.env.REACT_APP_API_URI + '/v4/auth/verify').then((res) => {
      dispatch(setLogin(token, res.data.data))
    }).catch((err) => {
      dispatch(Logout())
    })
    request.get(process.env.REACT_APP_API_URI + '/v4/header').then((res) => {
      dispatch(setSidebar(res.data.data))
    })
    request.get(process.env.REACT_APP_API_URI + '/v4/setting').then((res) => {
      dispatch(setSetting(res.data.data))
    })
    request.get(process.env.REACT_APP_API_URI + '/v4/auth/type').then((res) => {
      dispatch(setAccountType(res.data))
    })
  }, []);

  const handleNotificationClick = () => {
    setNotificationWindowOpen(!notificationWindowOpen);
  };

  const { setNodeRef, listeners, transform } = useDraggable({
    id: "draggable",
  });

  const location = useLocation();

  const menus = useSelector((state) => state.Sidebar.sidebar) || [];

  // const [defaultMainMenu, setDefaultMainMenu] = useState(menus[0]);
  function findTopMenuByUrl(array, targetUrl) {
    for (const item of array) {
      if (item?.url === targetUrl) {

        return item;
      }
      const foundMenu = item.menus?.find(menu => {
        return menu.url === targetUrl || (menu.children && menu.children.some(child => child.url === targetUrl));
      });

      if (foundMenu) {
        return item;
      }

      if (item.menus) {
        const foundInChildren = findTopMenuByUrl(item.menus, targetUrl);
        if (foundInChildren) {
          return item;
        }
      }
    }
    return null;
  }

  const getCurrentMainMenu = () => {
    const menuFind = findTopMenuByUrl(menus, location.pathname)
    return menuFind || menus?.[0] || {};
  };

  const currentMainMenu = getCurrentMainMenu();

  // let sidebarWidth = "230px";
  let sidebarWidth = "0px";

  const [notificationCount, setNotificationCount] = useState(0);

  const [hoveredMainMenu, setHoveredMainMenu] = useState(null);
  const [hoveredSubMenu, setHoveredSubMenu] = useState(null);
  function findTopLevelMenuByUrl(array, targetUrl) {
    if (!array) return null;
    for (const item of array) {
      // 현재 item's menus를 검사
      const foundMenu = item.menus?.find(menu => {
        // 현재 메뉴의 URL이 targetUrl과 일치하는지 검사
        return menu.url === targetUrl || (menu.children && menu.children.some(child => child.url === targetUrl));
      });

      // 일치하는 메뉴가 발견된 경우 최상위 객체 반환
      if (foundMenu) {
        return item; // 최상위 객체 반환
      }

      // 하위 메뉴를 재귀적으로 검사
      const foundInChildren = findTopLevelMenuByUrl(item.menus, targetUrl);
      if (foundInChildren) {
        return foundInChildren; // 최상위 객체 반환
      }
    }
    return null; // 해당 URL이 포함된 메뉴가 없을 경우 null 반환
  }
  // custom styled components
  const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
    zIndex: 1201,
    boxShadow: "none",
    padding: isLaptop ? "0.5rem 1rem 0rem 1rem" : "0rem 1rem",
    width: `calc(100% - ${isLaptop ? "0px" : sidebarWidth})`,
    marginLeft: isLaptop ? "0px" : sidebarWidth,
    backgroundColor: "#ffffff",
    color: theme.palette.text.primary,
    height: "auto",
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: 0,
  }));

  const isInMenuPath = (menuUrl) => {
    const pathname = location.pathname;
    const menuUrlParts = menuUrl.split("/").filter(Boolean);
    const pathnameParts = pathname.split("/").filter(Boolean);

    // Check if all parts of the menuUrl match the beginning of the pathname
    return menuUrlParts.every((part, index) => part === pathnameParts[index]);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    // Navigate to the corresponding URL
    // navigate(menus[newValue].url);
  };

  const getCurrentMenuIndex = () => {
    const index = menus?.findIndex((menu) =>
      location.pathname.startsWith(menu.url)
    );
    return index !== -1 ? index : 0; // Return 0 (클래스) if no match is found
  };

  useEffect(() => {
    setSelectedTab(menus.indexOf(findTopMenuByUrl(menus, location.pathname)))
  }, [location]);

  function findParentMenuIfLeafUrl(array, targetUrl) {
    for (const item of array) {
      if (!item?.menus) continue
      for (const menu of item.menus) {
        // 현재 메뉴의 자식 메뉴가 있는지 검사
        if (menu.children) {
          // 자식 메뉴 중에서 targetUrl과 일치하는 메뉴가 있는지 찾기
          const foundChild = menu.children.find(child => child.url === targetUrl);

          // 만약 targetUrl이 가장 하위 계층이라면
          if (foundChild) {
            return menu; // 부모 메뉴(현재 메뉴) 반환
          }
        }
      }

      // 하위 메뉴를 재귀적으로 검사
      const parentMenu = findParentMenuIfLeafUrl(item.menus, targetUrl);
      if (parentMenu) {
        return parentMenu; // 부모 메뉴 반환
      }
    }
    return null; // 해당 URL이 포함된 메뉴가 없을 경우 null 반환
  }
  const isMenuActive = (menuUrl) => {
    return location.pathname == (menuUrl)
  };

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningTitle, setWarningTitle] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const handleMenuClick = (data) => {
    if (data?.url) {
      if (data?.action_need_login) {
        if (!token) {
          setWarningTitle("[ 경고 ]");
          setWarningMessage("로그인 후 이용해주세요.");
          setOpenWarningModal(true);
          return
        }
      }
      if (data?.action_need_type) {
        if (!accountPermissionCompare(account?.type, data?.action_need_type)) {
          setWarningTitle("[ 경고 ]");
          setWarningMessage("권한이 없습니다.");
          setOpenWarningModal(true);
          return
        }
      }
      navigate(data.url);
      if (isMobile && mobileMenuOpen) {
        handleMobileMenuToggle();
      }
    } else {
      setWarningModalOpen(true);
    }
  };

  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  const handleMobileSearchToggle = () => {
    setMobileSearchOpen(!mobileSearchOpen);
  };

  useEffect(() => {
    setSelectedTab(menus.indexOf(findTopMenuByUrl(menus, location.pathname)))
  }, [mobileSearchOpen]);



  const dragItemSubMenu = useRef(null);
  const dragOverItemSubMenu = useRef(null);
  const dragStartSubMenu = (e, position) => {
    dragItemSubMenu.current = position;
  };

  const dragEnterSubMenu = (e, position) => {
    dragOverItemSubMenu.current = position;
  };

  const drop = (e) => {
    const temp = [...menus];
    const dragItemContent = temp[dragItem.current];
    temp.splice(dragItem.current, 1);
    temp.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    dispatch(setSidebar(temp))

    axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
      data: temp
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  };
  const dropSubMenu = (e) => {
    const tmp1 = currentMainMenu.menus;
    const temp = [...tmp1];
    const dragItemContent = temp[dragItemSubMenu.current];
    temp.splice(dragItemSubMenu.current, 1);
    temp.splice(dragOverItemSubMenu.current, 0, dragItemContent);
    const mainTemp = [...menus];
    mainTemp[menus.indexOf(currentMainMenu)].menus = temp;
    dispatch(setSidebar(mainTemp))
    axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
      data: mainTemp
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    dragItemSubMenu.current = null;
    dragOverItemSubMenu.current = null;
  };
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const dragItemThird = useRef(null);
  const dragOverItemThird = useRef(null);
  const dragStartThird = (e, position) => {
    dragItemThird.current = position;
  };
  const dragEnterThird = (e, position) => {
    dragOverItemThird.current = position;
  };

  const dropThird = (e) => {
    const tmp3 = currentSecondLevelMenu.children;
    const temp = [...tmp3];
    const dragItemContent = temp[dragItemThird.current];
    temp.splice(dragItemThird.current, 1);
    temp.splice(dragOverItemThird.current, 0, dragItemContent);
    const tmp1 = currentMainMenu.menus;
    const temp1 = [...tmp1];
    temp1[temp1.indexOf(currentSecondLevelMenu)].children = temp;
    const mainTemp = [...menus];
    mainTemp[menus.indexOf(currentMainMenu)].menus = temp1;
    dispatch(setSidebar(mainTemp))
    axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
      data: mainTemp
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  const MobilePanel = () => {
    const currentMenuIndex = selectedTab;
    const currentMenu = menus[currentMenuIndex];
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#fff",
          zIndex: 1300,
          display: "flex",
          flexDirection: "column",
          height: "100%", // Ensure full height
          overflow: "hidden", // Prevent body scrolling
          //scroll 가능하게
          WebkitOverflowScrolling: "touch",

        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            borderBottom: "1px solid #E1E1E1",
          }}
        >
          <Avatar
            src={account?.profile_image}
            alt="profile-photo"
            sx={{ width: 40, height: 40, marginRight: 1 }}
          />
          {
            account ? (
              <>
                <H6 fontWeight={600} marginRight={1.5}>
                  {account?.name || "비회원"}
                </H6>
                <Divider orientation="vertical" flexItem sx={{ my: 1, mr: 1.5 }} />
                <H6
                  mr={1}
                  fontWeight={600}
                  onClick={() => {
                    handleMobileMenuToggle()
                    navigate("/setting/my/article")
                  }}
                >
                  마이페이지
                </H6>
              </>
            ) : (
              <>

                {
                  window.location.hostname != "booksense.club" && (
                    <H6
                      mr={1}
                      fontWeight={600}
                      color="primary.darkViolet"
                      onClick={() => {
                        loginKakao()
                      }}
                    >
                      카카오 로그인
                    </H6>
                  )
                }
                <Divider orientation="vertical" flexItem sx={{ my: 1, mr: 1.5 }} />
                <H6
                  mr={1}
                  fontWeight={600}
                  color="primary.darkViolet"
                  onClick={() => {
                    login()
                  }}
                >
                  로그인
                </H6>
              </>
            )
          }
          {/* <H6 fontWeight={600} color="primary.darkViolet">
            내 강의실
          </H6> */}
          <IconButton
            onClick={handleMobileMenuToggle}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <X size={24} color={theme.palette.primary.dark} />
          </IconButton>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            sx={{
              "& .MuiTabs-scroller": {
                overflowX: "auto !important",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              },
              "& .MuiTabs-flexContainer": {
                gap: 0,
                "& .MuiTab-root": {
                  marginRight: "-8px",
                },
              }
            }}
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.primary.darkViolet },
            }}
          >
            {menus?.map((menu, index) => (
              <Tab
                key={index}
                label={menu.name}
                sx={{
                  color:
                    selectedTab === index
                      ? theme.palette.primary.darkViolet
                      : "inherit",
                  "&.Mui-selected": {
                    color: theme.palette.primary.darkViolet,
                  },
                  minWidth: "auto",
                  padding: "12px 16px",
                  fontSize: "0.875rem",
                }}
              />
            ))}
          </Tabs>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: "20px",
            WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS
            "-webkit-transform": "translateZ(0)", // Enable smooth scrolling on iOS
            "-webkit-backface-visibility": "hidden", // Enable smooth scrolling on iOS
          }}
        >
          {currentMenu &&
            currentMenu.menus?.map((subMenu, subIndex) => (
              <Box key={subIndex} mb={2}>
                <FlexBox mb={1} alignItems="center" onClick={() => {
                  handleMenuClick(subMenu)
                }}>
                  <Box
                    mr={1}
                    sx={{
                      width: "6px",
                      height: "6px",
                      backgroundColor: theme.palette.primary.darkViolet,
                      borderRadius: "50%",
                    }}
                  />
                  <H6 fontWeight={500} sx={{
                    color: location.pathname == (subMenu.url) || findParentMenuIfLeafUrl(menus, location.pathname)?.url == subMenu.url
                      ? theme.palette.primary.darkViolet
                      : "inherit"
                  }}>{subMenu.name}</H6>
                </FlexBox>
                <Grid container spacing={1}>
                  {subMenu.children?.map((child, childIndex) => (
                    <Grid item xs={6} key={childIndex}>
                      <Box
                        component="span"
                        onClick={() => handleMenuClick(child)}
                        sx={{
                          color: isMenuActive(child.url)
                            ? theme.palette.primary.darkViolet
                            : theme.palette.text.primary,
                          textDecoration: "none",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                        }}
                      >
                        {child.name}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {subIndex < currentMenu.menus.length - 1 && (
                  <Divider sx={{ my: 3 }} />
                )}
              </Box>
            ))}
        </Box>


        
        <Box
          sx={{
            backgroundColor: theme.palette.primary.darkBlue,
            padding: "15px 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {["이용안내", "고객센터", "공지사항"].map((item, index) => (
            <React.Fragment key={index}>
              <Box
                component="span"
                sx={{
                  color: "#fff",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleMenuClick("comingsoon")
                }}
              >
                {item}
              </Box>
              {index < 2 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    height: "1rem",
                    mx: 1,
                    mt: 0.5,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    );
  };

  const MobileSecondRow = styled(Box)(({ theme }) => ({
    overflowX: "auto",
    whiteSpace: "nowrap",
    WebkitOverflowScrolling: "touch",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    padding: "0px 0px",
  }));

  // Add this useEffect to reset showPanel when the location changes
  useEffect(() => {
    setShowPanel(false);
  }, [location]);

  const [showThirdLevel, setShowThirdLevel] = useState(true);

  const [showFullMenu, setShowFullMenu] = useState(false);

  const [currentSecondLevelMenu, setCurrentSecondLevelMenu] = useState(null);

  function findParentMenuByUrl(array, targetUrl) {
    for (const item of array) {
      for (const menu of (item?.menus || item?.children)) {
        if (menu?.children) {
          const foundChild = menu.children?.find(child => child.url === targetUrl);

          if (foundChild) {
            return menu;
          }
        }
      }

      const parentMenu = findParentMenuByUrl(item?.menus || [], targetUrl);
      if (parentMenu) {
        return parentMenu;
      }
    }
    return null;
  }
  useEffect(() => {
    // console.log(findParentMenuByUrl(menus,location.pathname))
    const currentMainMenu = findParentMenuByUrl(menus, location.pathname);
    if (currentMainMenu) {
      setCurrentSecondLevelMenu(currentMainMenu);
    } else {
      setCurrentSecondLevelMenu(null);
    }
  }, [location, menus]);
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
    };
  }, [mobileMenuOpen]);


  const [success, setSuccess] = useState(false);

  const [userToken, setUserToken] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [warning2, setWarning2] = useState(false);

  const loginKakao = () => {
    window.open('https://kauth.kakao.com/oauth/authorize?client_id=' + process.env.REACT_APP_KAKAO_LOGIN_CLIENT_KEY + '&redirect_uri=' + process.env.REACT_APP_API_URI + '/v4/auth/login/redirect/&response_type=code', "", "width=300px, height=300")
    window.addEventListener('message', ((e) => {
      if (e.data.type == "login") {
        setUserAccount(e.data.user)
        setUserToken(e.data.access_token)
        setTimeout(() => {
          setSuccess(true)
        }, 10)
      } else if (e.data.type == "reject") {
        setWarning2(true)
      }
    }), false);
  }
  const login = () => {
    setShowLoginModal(true)
    return
  }
  function updateObjById(arr, targetId, newData) {
    for (let obj of arr) {
      if (obj.id === targetId) {
        obj.name = newData.name
        obj.url = newData.url;
        obj.view_need_login = newData.view_need_login;
        obj.action_need_login = newData.action_need_login;
        obj.action_need_type = newData.action_need_type;
        obj.view_need_type = newData.view_need_type;
      }
      if (obj.menus && obj.menus.length > 0) {
        updateObjById(obj.menus, targetId, newData);
      }
      if (obj.children && obj.children.length > 0) {
        updateObjById(obj.children, targetId, newData);
      }
    }
    return arr;
  }
  const [deleteMenuData, setDeleteMenuData] = useState(null);
  const [deleteMenuQ, setDeleteMenuQ] = useState(false);
  function deleteObjById(arr, targetId) {
    for (let obj of arr) {
      if (obj.id === targetId) {
        arr.splice(arr.indexOf(obj), 1)
      }
      if (obj.menus && obj.menus.length > 0) {
        deleteObjById(obj.menus, targetId);
      }
      if (obj.children && obj.children.length > 0) {
        deleteObjById(obj.children, targetId);
      }
    }
    return arr;
  }
  const uniqueId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  }
  function addObjNextObjById(arr, targetId, newData) {
    for (let obj of arr) {
      if (obj.id === targetId) {
        arr.splice(arr.indexOf(obj) + 1, 0, newData)
      }
      if (obj.menus && obj.menus.length > 0) {
        addObjNextObjById(obj.menus, targetId, newData);
      }
      if (obj.children && obj.children.length > 0) {
        addObjNextObjById(obj.children, targetId, newData);
      }
    }
    return arr;
  }
  return (
    <>
      <FixHeaderModal editorData={editorData} showModal={openEditor} setShowModal={setEditorOpen} onClose={(e) => {
        setEditorOpen(false)
        const menuItem = e
        const temp = [...menus];
        const newMenus = updateObjById(temp, menuItem.id, menuItem)
        setEditorData(null)
        dispatch(setSidebar(newMenus))
        axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
          data: newMenus
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

      }} />

      <WarningModalV2 showModal={deleteMenuQ} setShowModal={setDeleteMenuQ} title={"메뉴 삭제"} description={"정말로 삭제하시겠습니까?"} action={""} onAction={() => {
        const temp = [...menus];
        const newMenus = deleteObjById(temp, deleteMenuData.id)
        dispatch(setSidebar(newMenus))
        axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
          data: newMenus
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      }} />
      <IconEditModal showModal={showIconEdit} setShowModal={setShowIconEdit} />
      <CompleteModal showModal={success} setShowModal={setSuccess} title={"로그인 성공"} description={"즐거운 시간되세요 :)"} onAction={() => {
        setShowLoginModal(false)
        setTimeout(() => {
          dispatch(setLogin(userToken, userAccount))
        }, 10)
      }} />
      <WarningModal showModal={warning2} setShowModal={setWarning2} title={"카카오톡 인증에 실패했습니다."} description={""} action={""} />
      {
        window.location.hostname == "booksense.club" ? (
          <LoginModalSimple open={showLoginModal} setShowLoginModal={setShowLoginModal} />
        ) : (
          <LoginModal open={showLoginModal} setShowLoginModal={setShowLoginModal} />
        )
      }
      {showFullMenu && !isMobile && <Overlay />}
      <WarningModal2 showModal={openWarningModal} setShowModal={setOpenWarningModal} title={warningTitle} description={warningMessage} />
      <DashboardHeaderRoot>
        {/* <SignupModal
          open={showSignupModal}
          setShowSignupModal={setShowSignupModal}
        />
        <LoginModal
          open={showLoginModal}
          setShowLoginModal={setShowLoginModal}
        /> */}

        {isLaptop && (
          <>
            <StyledToolBar>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NavLink to={`/`}>
                  <img
                    height={"29px"}
                    src={setting?.logo}
                    alt={"logo"}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </NavLink>
              </Box>

              <ClickAwayListener onClickAway={() => setSearchBar(false)}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    sx={{
                      height: 40,
                      width: 40,
                    }}
                    onClick={handleMobileSearchToggle}
                  >
                    <Search size={23} color={theme.palette.primary.dark} />
                  </IconButton>

                  <Box sx={{ position: "relative" }}>
                    <IconButton
                      sx={{
                        height: 40,
                        width: 40,
                      }}
                    // onClick={handleNotificationClick}
                    >
                      <Badge
                        badgeContent={notificationCount}
                        color="error"
                        sx={{
                          "& .MuiBadge-badge": {
                            right: 4,
                            top: 3,
                            border: `2px solid ${theme.palette.background.paper}`,
                            padding: "0px 3px 2px 3px",
                          },
                        }}
                      >
                        <Bell size={23} color={theme.palette.primary.dark} />
                      </Badge>
                    </IconButton>

                    {notificationWindowOpen && (
                      <AlarmWindow
                        onClose={() => setNotificationWindowOpen(false)}
                      />
                    )}
                  </Box>

                  <IconButton
                    sx={{
                      height: 40,
                      width: 40,
                    }}
                    onClick={handleMobileMenuToggle}
                  >
                    <AlignLeft size={23} color={theme.palette.primary.dark} />
                  </IconButton>
                </Box>
              </ClickAwayListener>
            </StyledToolBar>

            <MobileSecondRow>
              {currentMainMenu?.menus?.map((subMenu, index) => (
                <Box
                  key={index}
                  component="span"
                  onClick={() => handleMenuClick(subMenu)}
                  sx={{
                    display: "inline-block",
                    padding: "8px 18px 10px 0px",
                    color: location.pathname == (subMenu.url) || findParentMenuIfLeafUrl(menus, location.pathname)?.url == subMenu.url
                      ? theme.palette.primary.darkViolet
                      : theme.palette.text.primary,
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    cursor: "pointer",
                    position: "relative",
                    "&:hover": {
                      color: theme.palette.primary.darkViolet,
                    },
                    "&::after": location.pathname == (subMenu.url) || findParentMenuIfLeafUrl(menus, location.pathname)?.url == subMenu.url
                      ? {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 18, // Adjust this value to match the right padding
                        height: "2px",
                        backgroundColor: theme.palette.primary.darkViolet,
                      }
                      : {},
                  }}
                >
                  {subMenu.name}
                </Box>
              ))}
            </MobileSecondRow>

            {mobileSearchOpen && (
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1300,
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <H5>검색</H5>
                  <IconButton onClick={handleMobileSearchToggle}>
                    <X size={24} color={theme.palette.primary.dark} />
                  </IconButton>
                </Box>
                <GreySearchBar
                  isHeader={true}
                  open={mobileSearchOpen}
                  handleClose={handleMobileSearchToggle}
                  isMobile={isLaptop}
                />
              </Box>
            )}
          </>
        )}

        {showFullMenu && !isMobile && (
          <PanelContainer
            onMouseEnter={() => setShowFullMenu(true)}
            onMouseLeave={() => {
              setShowFullMenu(false);
              setHoveredMainMenu(null);
              setHoveredSubMenu(null);
            }}
          >
            <Row>
              {menus?.map((menu, menuIndex) => (
                <Column key={menuIndex} isFirst={menuIndex === 0}>
                  <ColumnItem isFirstColumn={menuIndex === 0} onClick={() => {
                    handleMenuClick(menu)
                  }}>
                    <H6 sx={{ fontWeight: 600 }}>{menu.name}</H6>
                    <ChevronRight
                      size={20}
                      color={theme.palette.primary.grey400}
                    />
                  </ColumnItem>
                  {menu.menus?.map((subMenu, subIndex) => (
                    <ColumnItem key={subIndex} isFirstColumn={menuIndex === 0}>
                      <Box
                        component="span"
                        onClick={() => handleMenuClick(subMenu)}
                        sx={{
                          color: "inherit",
                          textDecoration: "none",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        {subMenu.name}
                      </Box>
                    </ColumnItem>
                  ))}
                </Column>
              ))}
            </Row>
          </PanelContainer>
        )}

        <DndContext>
          <FlexBox
            sx={{ flexDirection: "column", alignItems: "center" }}
            onMouseLeave={() => {
              setHoveredMainMenu(null);
              setHoveredSubMenu(null);
              setShowPanel(false);
              setShowFullMenu(false);
            }}
          >
            {/* First row */}
            <StyledToolBar
              ref={setNodeRef}
              {...listeners}
              style={{
                width: "100%",
                maxWidth: "1200px",
                overflow: "visible",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                display: "flex",
                flexDirection: "row",
                transform: transform
                  ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
                  : undefined,
                WebkitOverflowScrolling: "touch",
              }}
            >
              {!isLaptop && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      minWidth: "220px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {window.location.hostname != "booksense.club" ? <NavLink to={`/`}>
                      {
                        accountPermissionCompare(account?.type, "정직원 매니저") ? (
                          <>
                            <ContextMenuTrigger id="context-logo" key={1}>
                              <img
                                height={"32px"}
                                src={setting?.logo}
                                alt={"logo"}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </ContextMenuTrigger>
                            <ContextMenu id="context-logo">
                              <ContextMenuItem onClick={() => {
                                setShowIconEdit(true)
                              }}>
                                아이콘 수정
                              </ContextMenuItem>
                            </ContextMenu>
                          </>
                        ) : (
                          <img
                            height={"32px"}
                            src={setting?.logo}
                            alt={"logo"}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        )
                      }
                    </NavLink> : null}
                    {accountPermissionCompare(account?.type, "마스터") ? menus?.map((menu, index) => (
                      <>
                        <ContextMenuTrigger id={"header1st-" + index} key={index}>
                          <Box
                            draggable
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={drop}
                            onDragOver={(e) => e.preventDefault()}
                            sx={{
                              whiteSpace: "nowrap",
                              fontWeight: 700,
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              color: isMenuActive(menu.url) || findTopLevelMenuByUrl(menus, location.pathname) === menu
                                ? theme.palette.primary.darkViolet
                                : "inherit",
                              marginLeft: menu.name === "홈피관리" ? "4px" : "20px",
                            }}
                            onClick={() => handleMenuClick(menu)}
                          >
                            {menu.name === "홈피관리" && (
                              <Settings
                                size={18}
                                style={{ marginLeft: "16px" }}
                                color={
                                  isMenuActive(menu.url) || findTopLevelMenuByUrl(menus, location.pathname) === menu
                                    ? theme.palette.primary.darkViolet
                                    : theme.palette.primary.dark
                                }
                              />
                            )}
                            {menu.name}
                          </Box>
                        </ContextMenuTrigger>
                        <ContextMenu id={"header1st-" + index}>
                          <ContextMenuItem onClick={() => {
                            setEditorData(menu)
                            setTimeout(() => {
                              setEditorOpen(true)
                            }, 10)
                          }}>
                            메뉴 수정
                          </ContextMenuItem>
                          <ContextMenuItem onClick={() => {
                            setDeleteMenuData(menu)
                            setTimeout(() => {
                              setDeleteMenuQ(true)
                            }, 10)
                          }}>
                            메뉴 삭제
                          </ContextMenuItem>
                          <ContextMenuItem onClick={() => {
                            const newMenu = {
                              id: uniqueId(),
                              name: "새 메뉴",
                              url: "/"+uniqueId(),
                              view_need_login: false,
                              action_need_login: false,
                              action_need_type: "",
                              view_need_type: "",
                              menus: []
                            }
                            const temp = [...menus];
                            const newMenus = addObjNextObjById(temp, menu.id, newMenu)
                            dispatch(setSidebar(newMenus))
                            axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
                              data: newMenus
                            }, {
                              headers: {
                                Authorization: `Bearer ${token}`
                              }
                            })
                          }}>
                            메뉴 추가
                          </ContextMenuItem>
                          <ContextMenuItem onClick={() => {
                            const newMenu = {
                              id: uniqueId(),
                              name: "새 메뉴",
                              url: "/"+uniqueId(),
                              view_need_login: false,
                              action_need_login: false,
                              action_need_type: "",
                              view_need_type: "",
                              children: []
                            }
                            const temp = [...menus];
                            
                            function updateTopObj(arr, targetId, newData) {
                              for (let obj of arr) {
                                if (obj.id === targetId) {
                                  obj.menus.push(newData)
                                }
                              }
                              return arr;
                            }
                            const newMenus = updateTopObj(temp, menu.id, newMenu)
                            dispatch(setSidebar(newMenus))
                            axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
                              data: newMenus
                            }, {
                              headers: {
                                Authorization: `Bearer ${token}`
                              }
                            })
                          }}>
                            하위 메뉴 추가
                          </ContextMenuItem>
                        </ContextMenu>
                      </>
                    )) : menus?.map((menu, index) => (
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          color: isMenuActive(menu.url) || findTopLevelMenuByUrl(menus, location.pathname) === menu
                            ? theme.palette.primary.darkViolet
                            : "inherit",
                          marginLeft: menu.name === "홈피관리" ? "4px" : "20px",
                        }}
                        onClick={() => handleMenuClick(menu)}
                      >
                        {menu.name === "홈피관리" && (
                          <Settings
                            size={18}
                            style={{ marginLeft: "16px" }}
                            color={
                              isMenuActive(menu.url) || findTopLevelMenuByUrl(menus, location.pathname) === menu
                                ? theme.palette.primary.darkViolet
                                : theme.palette.primary.dark
                            }
                          />
                        )}
                        {menu.name}
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      ml={1}
                      mr={2}
                      my={1}
                      sx={{
                        width: "100%",
                        minWidth: "400px",
                        padding: "0 0 0 0",
                        position: "relative",
                        zIndex: 1202,
                      }}
                    >
                      <GreySearchBar isHeader={true} isMobile={isMobile} />
                    </Box>

                    <Box sx={{ position: "relative" }}>
                      <IconButton
                        sx={{
                          borderRadius: "10px",
                          border: "1px solid #E1E1E1",
                          height: 40,
                          width: 40,
                        }}
                      // onClick={handleNotificationClick}
                      >
                        <Badge
                          badgeContent={notificationCount}
                          color="error"
                          sx={{
                            "& .MuiBadge-badge": {
                              right: 4,
                              top: 3,
                              border: `2px solid ${theme.palette.background.paper}`,
                              padding: "0px 3px 2px 3px",
                            },
                          }}
                        >
                          <Bell size={23} color={theme.palette.primary.dark} />
                        </Badge>
                      </IconButton>

                      {notificationWindowOpen && (
                        <AlarmWindow
                          onClose={() => setNotificationWindowOpen(false)}
                        />
                      )}
                    </Box>

                    {
                      account || window.location.hostname == "booksense.club" ? (
                        null
                      ) : (
                        <IconButton
                          sx={{
                            marginLeft: "10px",
                            borderRadius: "10px",
                            border: "1px solid #E1E1E1",
                            height: 40,
                            padding: "0 10px",
                            color: 'black',
                            backgroundColor: "#F7E600",
                            "&:hover": {
                              backgroundColor: "#F7E600",
                            },
                          }}
                          onClick={() => {
                            loginKakao()
                          }}
                        >
                          <img
                            src={kakao}
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "2px",
                            }}
                          />
                          <FlexBox alignItems="center">
                            <H6 fontWeight={600}>
                              카카오 로그인
                            </H6>
                          </FlexBox>
                        </IconButton>
                      )
                    }

                    <IconButton
                      sx={{
                        marginLeft: "10px",
                        borderRadius: "10px",
                        border: "1px solid #E1E1E1",
                        height: 40,
                        padding: "0 10px",
                      }}
                      onClick={() => {
                        if (account) {
                          window.location.href = '/setting/my/article'
                        } else {
                          login()
                        }
                      }}
                    >
                      <FlexBox alignItems="center">
                        <Avatar
                          src={account?.profile_image}
                          alt={"profile-photo"}
                          sx={{ mr: 0.5, width: 30, height: 30 }}
                        />
                        {
                          account ? (
                            <H6 fontWeight={600} onClick={() => {
                              
                            }}>
                              마이페이지
                            </H6>
                          ) : (
                            <H6 fontWeight={600}>
                              로그인
                            </H6>
                          )
                        }
                      </FlexBox>
                    </IconButton>
                  </Box>
                </>
              )}
            </StyledToolBar>

            {/* Second row */}
            <StyledToolBar
              ref={setNodeRef}
              {...listeners}
              style={{
                width: "100%",
                maxWidth: "1200px",
                overflow: "visible",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                display: "flex",
                flexDirection: "row",
                transform: transform
                  ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
                  : undefined,
                WebkitOverflowScrolling: "touch",
              }}
            >
              {!isLaptop && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <FlexBox
                    pt={1}
                    pb={2}
                    alignItems="center"
                    onMouseEnter={() => {
                      setShowFullMenu(true);
                      setHoveredMainMenu("카테고리");
                    }}
                    sx={{ position: "relative", marginRight: "20px" }}
                  >
                    <Menu
                      size={19}
                      color={
                        hoveredMainMenu === "카테고리12341234"
                          ? theme.palette.primary.darkViolet
                          : theme.palette.primary.dark
                      }
                    />
                    <H6 fontWeight={600} style={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      color:
                        hoveredMainMenu === "카테고리12341234"
                          ? theme.palette.primary.darkViolet
                          : "inherit",
                      marginLeft: "4px",
                    }}>
                      카테고리
                    </H6>
                    {hoveredMainMenu === "카테고리" && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -2,
                          left: 0,
                          right: 0,
                          height: 2,
                          backgroundColor: theme.palette.primary.darkViolet,
                        }}
                      />
                    )}
                  </FlexBox>

                  {accountPermissionCompare(account?.type, "마스터") ? currentMainMenu.menus?.map((subMenu, index) => (
                    <>
                      <ContextMenuTrigger id={"header2nd-" + index} key={index}>
                        <H6
                          key={index}
                          pt={1}
                          pb={2}
                          draggable
                          onDragStart={(e) => dragStartSubMenu(e, index)}
                          onDragEnter={(e) => dragEnterSubMenu(e, index)}
                          onDragEnd={dropSubMenu}
                          onDragOver={(e) => e.preventDefault()}
                          sx={{
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                            marginRight: "20px",
                            cursor: "pointer",
                            position: "relative",
                            color: location.pathname == (subMenu.url) || findParentMenuIfLeafUrl(menus, location.pathname)?.url == subMenu.url
                              ? theme.palette.primary.darkViolet
                              : "inherit",
                            "&:hover": {
                              color: theme.palette.primary.darkViolet,
                            },
                          }}
                          onMouseEnter={() => {
                            setHoveredSubMenu(subMenu);
                            setCurrentSecondLevelMenu(subMenu);
                            setShowPanel(true);
                            setShowFullMenu(false);
                          }}
                          onClick={() => handleMenuClick(subMenu)}
                        >
                          {subMenu.name}
                          {(hoveredSubMenu == subMenu || location.pathname == (subMenu.url) || findParentMenuIfLeafUrl(menus, location.pathname)?.url == subMenu.url) && (
                            <Box
                              sx={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                                height: 2,
                                backgroundColor: theme.palette.primary.darkViolet,
                              }}
                            />
                          )}
                        </H6>
                      </ContextMenuTrigger>
                      <ContextMenu id={"header2nd-" + index}>
                        <ContextMenuItem onClick={() => {
                          setEditorData(subMenu)
                          setTimeout(() => {
                            setEditorOpen(true)
                          }, 10)
                        }}>
                          메뉴 수정
                        </ContextMenuItem>
                        <ContextMenuItem onClick={() => {
                          setDeleteMenuData(subMenu)
                          setTimeout(() => {
                            setDeleteMenuQ(true)
                          }, 10)
                        }}>
                          메뉴 삭제
                        </ContextMenuItem>
                          <ContextMenuItem onClick={() => {
                            const newMenu = {
                              id: uniqueId(),
                              name: "새 메뉴",
                              url: "/"+uniqueId(),
                              view_need_login: false,
                              action_need_login: false,
                              action_need_type: "",
                              view_need_type: "",
                              menus: []
                            }
                            const temp = [...menus];
                            const newMenus = addObjNextObjById(temp, subMenu.id, newMenu)
                            dispatch(setSidebar(newMenus))
                            axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
                              data: newMenus
                            }, {
                              headers: {
                                Authorization: `Bearer ${token}`
                              }
                            })
                          }}>
                            메뉴 추가
                          </ContextMenuItem>
                          <ContextMenuItem onClick={() => {
                            const newMenu = {
                              id: uniqueId(),
                              name: "새 메뉴",
                              url: "/"+uniqueId(),
                              view_need_login: false,
                              action_need_login: false,
                              action_need_type: "",
                              view_need_type: "",
                              children: []
                            }
                            const temp = [...menus];
                            
                            function updateTopObj(arr, targetId, newData) {
                              for (let obj of arr) {
                                if (obj.id === targetId) {
                                  obj.children.push(newData)
                                }
                                if (obj.menus && obj.menus.length > 0) {
                                  updateTopObj(obj.menus, targetId, newData);
                                }
                              }
                              return arr;
                            }
                            const newMenus = updateTopObj(temp, subMenu.id, newMenu)
                            dispatch(setSidebar(newMenus))
                            axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
                              data: newMenus
                            }, {
                              headers: {
                                Authorization: `Bearer ${token}`
                              }
                            })
                          }}>
                            하위 메뉴 추가
                          </ContextMenuItem>
                      </ContextMenu>
                    </>
                  )) : (
                    currentMainMenu.menus?.map((subMenu, index) => (
                      <H6
                        key={index}
                        pt={1}
                        pb={2}
                        sx={{
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                          marginRight: "20px",
                          cursor: "pointer",
                          position: "relative",
                          color: location.pathname == (subMenu.url) || findParentMenuIfLeafUrl(menus, location.pathname)?.url == subMenu.url
                            ? theme.palette.primary.darkViolet
                            : "inherit",
                          "&:hover": {
                            color: theme.palette.primary.darkViolet,
                          },
                        }}
                        onMouseEnter={() => {
                          setHoveredSubMenu(subMenu);
                          setCurrentSecondLevelMenu(subMenu);
                          setShowPanel(true);
                          setShowFullMenu(false);
                        }}
                        onClick={() => handleMenuClick(subMenu)}
                      >
                        {subMenu.name}
                        {(hoveredSubMenu == subMenu || location.pathname == (subMenu.url) || findParentMenuIfLeafUrl(menus, location.pathname)?.url == subMenu.url) && (
                          <Box
                            sx={{
                              position: "absolute",
                              left: 0,
                              right: 0,
                              height: 2,
                              backgroundColor: theme.palette.primary.darkViolet,
                            }}
                          />
                        )}
                      </H6>
                    ))
                  )}
                </Box>
              )}
            </StyledToolBar>

            {accountPermissionCompare(account?.type, "마스터") && currentSecondLevelMenu && !isMobile &&
              currentSecondLevelMenu.children &&
              currentSecondLevelMenu.children.length > 0 && (
                <>
                  <ThirdLevelRow>
                    <Box
                      sx={{ display: "flex", maxWidth: "1200px", width: "100%" }}
                    >
                      {currentSecondLevelMenu.children?.map((child, index) => (
                        <>
                          <ContextMenuTrigger id={"header3rd-" + index} key={index}>
                            <ThirdLevelMenuItem
                              key={index}
                              draggable
                              onDragStart={(e) => dragStartThird(e, index)}
                              onDragEnter={(e) => dragEnterThird(e, index)}
                              onDragEnd={dropThird}
                              onDragOver={(e) => e.preventDefault()}
                              onClick={() => handleMenuClick(child)}
                              sx={{
                                color: isMenuActive(child.url)
                                  ? theme.palette.primary.darkViolet
                                  : theme.palette.text.primary,
                                position: "relative",
                              }}
                            >
                              {child.name}
                            </ThirdLevelMenuItem>
                          </ContextMenuTrigger>
                          <ContextMenu id={"header3rd-" + index}>
                            <ContextMenuItem onClick={() => {
                              setEditorData(child)
                              setTimeout(() => {
                                setEditorOpen(true)
                              }, 10)
                            }}>
                              메뉴 수정
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => {
                              setDeleteMenuData(child)
                              setTimeout(() => {
                                setDeleteMenuQ(true)
                              }, 10)
                            }}>
                              메뉴 삭제
                            </ContextMenuItem>
                          <ContextMenuItem onClick={() => {
                            const newMenu = {
                              id: uniqueId(),
                              name: "새 메뉴",
                              url: "/"+uniqueId(),
                              view_need_login: false,
                              action_need_login: false,
                              action_need_type: "",
                              view_need_type: "",
                              menus: []
                            }
                            const temp = [...menus];
                            const newMenus = addObjNextObjById(temp, child.id, newMenu)
                            dispatch(setSidebar(newMenus))
                            axios.post(process.env.REACT_APP_API_URI + '/v4/header', {
                              data: newMenus
                            }, {
                              headers: {
                                Authorization: `Bearer ${token}`
                              }
                            })
                          }}>
                            메뉴 추가
                          </ContextMenuItem>
                          </ContextMenu>
                        </>
                      ))}
                    </Box>
                  </ThirdLevelRow>
                </>
              )}

            {!accountPermissionCompare(account?.type, "마스터") && currentSecondLevelMenu && !isMobile &&
              currentSecondLevelMenu.children &&
              currentSecondLevelMenu.children.length > 0 && (
                <ThirdLevelRow>
                  <Box
                    sx={{ display: "flex", maxWidth: "1200px", width: "100%" }}
                  >
                    {currentSecondLevelMenu.children?.map((child, index) => (
                      <ThirdLevelMenuItem
                        key={index}
                        onClick={() => handleMenuClick(child)}
                        sx={{
                          color: isMenuActive(child.url)
                            ? theme.palette.primary.darkViolet
                            : theme.palette.text.primary,
                          position: "relative",
                        }}
                      >
                        {child.name}
                      </ThirdLevelMenuItem>
                    ))}
                  </Box>
                </ThirdLevelRow>
              )}
          </FlexBox>
        </DndContext>
      </DashboardHeaderRoot>

      {mobileMenuOpen && <MobilePanel />}

      <WarningModal
        showModal={warningModalOpen}
        setShowModal={setWarningModalOpen}
        title="경고"
        messages={["아직 준비중인 서비스입니다."]}
      />
    </>
  );
};

export default DashboardHeaderNew;
