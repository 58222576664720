import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Modal,
  Select,
  styled,
  useMediaQuery,
} from "@mui/material";
import { ButtonText, Tiny } from "components/Typography";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

export default function FixHeaderModal({ showModal, setShowModal,onClose,editorData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "48%",
    maxHeight: "90vh",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    boxShadow: 24,
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
    },
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={handleClose}
        closeOnClick={true}
        disableEnforceFocus={true}
        disableBackdropClick={false} // Disable closing when clicking on the backdrop
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backdropFilter: "blur(5px)",
          backgroundColor: "transparent",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Card sx={style}>
          <Grid py={4} sx={{ borderRadius: "10px" }}>
            <Box
              sx={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0px 16px 16px 0px",
                background: "white",
              }}
            >
              <Box
                onClick={() => handleClose()}
                sx={{
                  width: "32px",
                  height: "32px",
                  backgroundColor: theme.palette.borderColor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 100,
                  cursor: "pointer",
                }}
              >
                <X color={theme.palette.grey400} />
              </Box>
            </Box>
            <Box px={4} pb={2}>
              <Curriculum editorData={editorData} closeModal={(e) => {
            if (onClose) {
              onClose(e)
            }
            setShowModal(false)
          }}/>
            </Box>
          </Grid>
        </Card>
      </Modal>
    </>
  );
}

function Curriculum({editorData, closeModal}) {
  const theme = useTheme();
  const token = useSelector((state) => state.Auth.token);
  const fileInputRef = useRef(null);
  const [uploading , setUploading] = useState(false);
  const [video_url , setVideoUrl] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isLargeScreen = window.innerWidth > 1720;

  const UploadButton = styled(Box)(({ theme }) => ({
    width: "fit-content",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: theme.palette.borderColor,
    backgroundColor: theme.palette.primary.grey700,
  }));

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [viewNeedLogin, setViewNeedLogin] = useState(false);
  const [viewNeedType, setViewNeedType] = useState("제한없음");
  const [actionNeedLogin, setActionNeedLogin] = useState(false);
  const [actionNeedType, setActionNeedType] = useState("제한없음");


  const userTypes = useSelector((state) => state.Sidebar.accountType);

  useEffect(() => {
    setTitle(editorData?.name || "");
    setUrl(editorData?.url || "");
    setViewNeedType(editorData?.view_need_type || "제한없음");
    setActionNeedType(editorData?.action_need_type || "제한없음");
    setViewNeedLogin(editorData?.view_need_login == undefined ? false : editorData?.view_need_login);
    setActionNeedLogin(editorData?.action_need_login == undefined ? false : editorData?.action_need_login);
  }, [editorData]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <ButtonText mb={1}>이름</ButtonText>
            <AppTextField
              fullWidth
              name="title"
              placeholder="이름을 입력해 주세요"
              value={title}
              onChange={handleTitleChange}
              sx={{
                backgroundColor: "#ffffff",
                "& .MuiOutlinedInput-input::placeholder": {
                  fontWeight: 300,
                  fontSize: 15.5,
                  color: "#B5B7C0",
                },
              }}
              inputProps={{ style: { height: 18 } }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <ButtonText mb={1}>이동될 URL</ButtonText>
            <AppTextField
              fullWidth
              name="title"
              placeholder="URL을 입력해 주세요"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              sx={{
                backgroundColor: "#ffffff",
                "& .MuiOutlinedInput-input::placeholder": {
                  fontWeight: 300,
                  fontSize: 15.5,
                  color: "#B5B7C0",
                },
              }}
              inputProps={{ style: { height: 18 } }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FlexRowAlign gap={2}>
            <Grid item xs={6}>
              <ButtonText mb={1}>표시 권한 선택</ButtonText>
              <MySelect
                items={Object.keys(userTypes)}
                width={"100%"}
                height={"50px"}
                onChange={(e) => setViewNeedType(e.target.value)}
                defaultValue={viewNeedType}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonText mb={1}>비로그인 표시 여부</ButtonText>
              <MySelect
                items={["표시", "비표시"]}
                width={"100%"}
                height={"50px"}
                onChange={(e) => setViewNeedLogin(e.target.value === "비표시")}
                defaultValue={viewNeedLogin ? "비표시" : "표시"}
              />
            </Grid>
          </FlexRowAlign>
        </Grid>

        <Grid item xs={12}>
          <FlexRowAlign gap={2}>
            <Grid item xs={6}>
              <ButtonText mb={1}>수행 권한 선택</ButtonText>
              <MySelect
                items={Object.keys(userTypes)}
                width={"100%"}
                height={"50px"}
                onChange={(e) => setActionNeedType(e.target.value)}
                defaultValue={actionNeedType}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonText mb={1}>비로그인 수행 여부</ButtonText>
              <MySelect2
                items={["수행", "비수행"]}
                width={"100%"}
                height={"50px"}
                onChange={(e) => {
                  setActionNeedLogin(e.target.value === "비수행")
                }}
                defaultValue={actionNeedLogin}
              />
            </Grid>
          </FlexRowAlign>
        </Grid>


        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
              borderRadius: 2,
              background: theme.palette.primary.grey700,
            }}
            onClick={() => {
              closeModal({
                name: title,
                url: url,
                view_need_type: viewNeedType,
                view_need_login: viewNeedLogin,
                action_need_type: actionNeedType,
                action_need_login: actionNeedLogin,
                id: editorData.id,
              })
            }}
          >
            <ButtonText>수정</ButtonText>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}


function MySelect2({
  items,
  width,
  height,
  backgroundColor,
  onChange,
  defaultValue,
}) {
  const theme = useTheme();
  console.log(defaultValue)
  const handleChange = (event) => {
    onChange(event)
  };

  return (
    <>
      <FlexRowAlign gap={2}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={defaultValue ? "비수행" : "수행"}
          // placeholder="제목 + 내용"
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "0",
                margin: "0",
              },
            },
          }}
          input={
            <InputBase
              sx={{
                height: height ? height : 40,
                width: width ? width : 110,
                fontSize: 14,
                textAlign: "left",
                paddingLeft: "10px !important",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "8px",
                color: "text.primary",
                backgroundColor: backgroundColor ? backgroundColor : "#ffffff",
                marginBottom: 0,
                border: "1px solid #E1E1E1",
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                },
                "& > .MuiSelect-select": {
                  paddingRight: "0 !important",
                },
              }}
            />
          }
        >
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                sx={{
                  height: height ? height : 40,
                  width: width ? width : 110,
                  fontSize: 14,
                  fontWeight: 300,
                  border: "1px solid #E1E1E1",
                }}
                value={item}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FlexRowAlign>
    </>
  );
}


function MySelect({
  items,
  width,
  height,
  backgroundColor,
  onChange,
  defaultValue,
}) {
  const theme = useTheme();

  const handleChange = (event) => {
    onChange(event)
  };

  return (
    <>
      <FlexRowAlign gap={2}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={defaultValue}
          // placeholder="제목 + 내용"
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "0",
                margin: "0",
              },
            },
          }}
          input={
            <InputBase
              sx={{
                height: height ? height : 40,
                width: width ? width : 110,
                fontSize: 14,
                textAlign: "left",
                paddingLeft: "10px !important",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "8px",
                color: "text.primary",
                backgroundColor: backgroundColor ? backgroundColor : "#ffffff",
                marginBottom: 0,
                border: "1px solid #E1E1E1",
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                },
                "& > .MuiSelect-select": {
                  paddingRight: "0 !important",
                },
              }}
            />
          }
        >
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                sx={{
                  height: height ? height : 40,
                  width: width ? width : 110,
                  fontSize: 14,
                  fontWeight: 300,
                  border: "1px solid #E1E1E1",
                }}
                value={item}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FlexRowAlign>
    </>
  );
}
