import React, { useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import { Button, Card, Grid, useMediaQuery, Modal, styled, Box, Select, InputBase, MenuItem, IconButton, InputAdornment } from "@mui/material";
// ----------------------------------
import kakaoLoginPage from "assets/jiwongum/kakao-login-page.png";
// ----------------------------------
import IconX from "assets/icon-close-modal.svg";
import { useDispatch } from "react-redux";
import { setLogin } from "../../reducer/authReducer";
import CompleteModal from "page/modal/alerts/SuccessModal";
import WarningModal from "page/modal/alerts/WarningModal";
import Curriculum from "./Curriculum";
import { ButtonText, H1, H3, Tiny } from "components/Typography";
import { useSelector } from "react-redux";
import { useRef } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import FlexBox from "components/flexbox/FlexBox";
import { Link, Plus } from "react-feather";
import { KeyboardArrowDown } from "@mui/icons-material";
import AppCheckBox from "components/AppCheckBox";
export default function EditMenuModal({ index, showModal, setShowModal, onAction, item }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const token = useSelector((state) => state.Auth.token);
  const fileInputRef = useRef(null);
  const mobileFileInputRef = useRef(null);
  const iconFileRef = useRef(null);
  const bannerFileRef = useRef(null);
  const tabIconFileRef = useRef(null);
  const userTypes = useSelector((state) => state.Sidebar.accountType);

  const [accessUserType, setAccessUserType] = useState(item?.need_type || "제한없음");
  const [displayUserType, setDisplayUserType] = useState(item?.type || "제한없음");
  const [mainBoardAccessUserType, setMainBoardAccessUserType] = useState("제한없음");
  const [mainBoarddisplayUserType, setMainBoardDisplayUserType] = useState("제한없음");
  const [defaultExpanded, setDefaultExpanded] = useState(item.expanded);
  const [createMainCategoryBoard, setCreateMainCategoryBoard] = useState(false);
  const [accessLogin, setAccessLogin] = useState(item.login);
  const [needLogin, setNeedLogin] = useState(item.need_login);
  const [bannerFile, setBannerFile] = useState(null);
  const [iconUrl, setIconUrl] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(null);
  const [boardName, setBoardName] = useState("");
  const [boardPath, setBoardPath] = useState("");

  useEffect(() => {
    setAccessUserType(item.need_type || "제한없음")
    setDisplayUserType(item.type || "제한없음")
    setDefaultExpanded(item.expanded)
    setAccessLogin(item.login)
    setNeedLogin(item.need_login)
    setIconUrl(item.selectedIcon)
  }, [item])

  useEffect(() => {
    if (item.path.startsWith('/board/')) {
      setCreateMainCategoryBoard(true)
      axios.get(process.env.REACT_APP_API_URI+'/v4/board/'+item.path.replace('/board/','')+"?page=1&limit=1", {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((res) => {
        const data = res.data.data.board_data
        setBoardName(data.name)
        setBoardPath(data.id)
        setMainBoardAccessUserType(data.viewer_user_type)
        setMainBoardDisplayUserType(data.default_user_type)
        setBannerUrl(data.banner)
      })
    }
  },[])


  const UploadButton = styled(Box)(({ theme }) => ({
    width: "fit-content",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: theme.palette.borderColor,
    backgroundColor: theme.palette.primary.grey700,
  }));

  const [title, setTitle] = useState(item.title || (item.name || ""));
  const [subtitle, setSubtitle] = useState("");
  const [buttonText, setButtonText] = useState("");

  const [PCImageFile, setPCImageFile] = useState(null);
  const [mobileImageFile, setMobileImageFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [link, setLink] = useState("");
  const [buttonLink, setButtonLink] = useState(item.path);
  const [iconFile, setIconFile] = useState(null);

  const [bannerCycle, setBannerCycle] = useState("");
  const [selectedType, setSelectedType] = useState("게시판뷰");

  const [tabName, setTabName] = useState("");
  const [tabIconFile, setTabIconFile] = useState(null);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };


  const handleButtonLinkChange = (event) => {
    setButtonLink(event.target.value);
  };

  const handleBannerFileInputChange = (e) => {

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    axios.post(process.env.REACT_APP_API_URI+'/v4/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      }
    }).then((res) => {
      const url = res.data.data.file_url
      setBannerUrl(url)
    })
    setBannerFile(file);
  };

  const handleTabIconFileInputChange = (e) => {
    const file = e.target.files[0];
    setTabIconFile(file);
  };

  const handleTabNameChange = (event) => {
    setTabName(event.target.value);
  };
  console.log(item)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "105%" : "46%",
    maxHeight: "90vh",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    boxShadow: 24,
    p: 0,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
  };
  return (
    <>
      <Toaster />
      <Modal
        open={showModal}
        onClose={() => { setShowModal(false) }}
        closeOnClick={true}
        disableEnforceFocus={true}
        disableBackdropClick={false} // Disable closing when clicking on the backdrop
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(5px)", backgroundColor: "transparent" }}
      >
        <Card container spacing={2} style={style}>
          <div style={{padding: 10}}>
          <FlexRowAlign
            mb={4}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H3>메뉴 에디터</H3>

            <Grid item width={110}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: 15.5,
                  fontWeight: 500,
                  borderRadius: 2,
                  minWidth: 110,
                  width: 110,
                  borderRadius: 2,
                  background: theme.palette.gradient.purpletoblue,
                  color: "white",
                }}
                onClick={() => {
                  console.log(iconUrl)
                    onAction({
                      menu_data: {
                        title: title,
                        type: displayUserType,
                        need_type: accessUserType,
                        expanded: defaultExpanded,
                        login: accessLogin,
                        need_login: needLogin,
                        icon: iconUrl,
                        path: buttonLink,
                      },
                      board_data: {
                        name: boardName,
                        viewer_user_type: mainBoardAccessUserType,
                        default_user_type: mainBoarddisplayUserType,
                        banner: bannerUrl,
                        id: boardPath
                      }
                    })
                }}
              >
                저장
              </Button>
            </Grid>
          </FlexRowAlign>
          <Grid container spacing={5}>
        <Grid item xs={12}>
          <ButtonText mb={1}>카테고리 이름</ButtonText>

          <FlexBox
            gap={2}
            sx={{ justifyContent: "flex-start", flexWrap: "wrap" }}
          >
            <AppTextField
              name="title"
              placeholder="추가할 카테고리 이름을 입력해주세요"
              value={title}
              onChange={handleTitleChange}
              sx={{
                minWidth: "200px",
                maxWidth: "450px",
                width: "100%",
                backgroundColor: "#ffffff",
                "& .MuiOutlinedInput-input::placeholder": {
                  fontWeight: 300,
                  fontSize: 15.5,
                  color: "#B5B7C0",
                },
              }}
              inputProps={{ style: { height: 18 } }}
            />
            
          </FlexBox>
        </Grid>

        <Grid item xs={12}>
          <FlexRowAlign
            item
            sx={{ width: "100%", justifyContent: "flex-start" }}
          >
            <Grid item lg={3} xs={3} mr={3}>
              <ButtonText mb={1}>접근 권한</ButtonText>
              <Select
                onChange={(e) => setAccessUserType(e.target.value || "제한없음")}
                IconComponent={() => <KeyboardArrowDown sx={{ color: "#B5B7C0" }} />}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      padding: "0",
                      margin: "0",
                    },
                  },
                }}
                input={
                  <InputBase
                    value={accessUserType}
                    sx={{
                      height: 51,
                      width: "100%",
                      fontSize: 15.5,
                      borderRadius: "8px",
                      color: "text.primary",
                      backgroundColor: "#ffffff",
                      marginBottom: 0,
                      paddingLeft: 1,
                      paddingRight: 1,
                      border: "1px solid #E1E1E1",
                      "& .MuiPopover-paper": {
                        boxShadow: "none",
                      },
                    }}
                  />
                }
              >
                {
                  Object.keys(userTypes).reverse().map((key) => {
                    return (
                      <MenuItem
                        sx={{
                          height: 51,
                          width: "100%",
                          fontSize: 14,
                          fontWeight: 300,
                          border: "1px solid #E1E1E1",
                        }}
                        value={key}
                      >
                        {key}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item lg={3} xs={3}>
              <ButtonText mb={1}>확인(표시) 권한</ButtonText>
              <Select
                onChange={(e) => setDisplayUserType(e.target.value || "제한없음")}
                IconComponent={() => <KeyboardArrowDown sx={{ color: "#B5B7C0" }} />}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      padding: "0",
                      margin: "0",
                    },
                  },
                }}
                input={
                  <InputBase
                    value={displayUserType}
                    sx={{
                      height: 51,
                      width: "100%",
                      fontSize: 15.5,
                      borderRadius: "8px",
                      color: "text.primary",
                      backgroundColor: "#ffffff",
                      marginBottom: 0,
                      paddingLeft: 1,
                      paddingRight: 1,
                      border: "1px solid #E1E1E1",
                      "& .MuiPopover-paper": {
                        boxShadow: "none",
                      },
                    }}
                  />
                }
              >
              {
                Object.keys(userTypes).reverse().map((key) => {
                  return (
                    <MenuItem
                      sx={{
                        height: 51,
                        width: "100%",
                        fontSize: 14,
                        fontWeight: 300,
                        border: "1px solid #E1E1E1",
                      }}
                      value={key}
                    >
                      {key}
                    </MenuItem>
                  )
                })
              }
              </Select>
            </Grid>
          </FlexRowAlign>
        </Grid>

        <Grid item xs={12}>
          <FlexRowAlign sx={{ justifyContent: "flex-start" }}>
            
          {
            item?.title && (
              <Grid item lg={2} xs={1}>
            <ButtonText mb={1.5}>펼침 여부</ButtonText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                mr={2}
                sx={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}
              >
                <AppCheckBox checked={defaultExpanded} onChange={() => {
                  setDefaultExpanded(!defaultExpanded);
                }} />
              </Box>
            </Box>
          </Grid>
            )
          }
          <Grid item lg={2} xs={2}>
            <ButtonText mb={1.5}>비회원 표시 여부</ButtonText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                mr={2}
                sx={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}
              >
                <AppCheckBox checked={needLogin} onChange={() => {
                  setNeedLogin(!needLogin);
                }} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={2} xs={2}>
            <ButtonText mb={1.5}>비회원 접근 여부</ButtonText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                mr={2}
                sx={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}
              >
                <AppCheckBox checked={accessLogin} onChange={() => {
                  setAccessLogin(!accessLogin);
                }} />
              </Box>
            </Box>
          </Grid>
          
          {
            item?.title && (
              <Grid item lg={2} xs={1}>
            <ButtonText mb={1.5}>게시판 생성</ButtonText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                mr={2}
                sx={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}
              >
                <AppCheckBox checked={createMainCategoryBoard} onChange={() => {
                  setCreateMainCategoryBoard(!createMainCategoryBoard);
                }} />
              </Box>
            </Box>
          </Grid>
            )
          }
            </FlexRowAlign>
        </Grid>
        <Grid item xs={12}>
          {
            createMainCategoryBoard && (
              <>
                <ButtonText mb={1}>게시판 이름</ButtonText>
                <FlexRowAlign gap={2} sx={{ justifyContent: "flex-start" }}>
                  <AppTextField
                    name="title"
                    placeholder="게시판 이름을 입력해주세요"
                    value={boardName}
                    onChange={(event) => {setBoardName(event.target.value)}}
                    sx={{
                      backgroundColor: "#ffffff",
                      maxWidth: "450px",
                      width: "100%",
                      "& .MuiOutlinedInput-input::placeholder": {
                        fontWeight: 300,
                        fontSize: 15.5,
                        color: "#B5B7C0",
                      },
                    }}
                    inputProps={{ style: { height: 18 } }}
                  />
                </FlexRowAlign>
                <ButtonText mb={1}>게시판 주소</ButtonText>
                <FlexRowAlign gap={2} sx={{ justifyContent: "flex-start" }}>
                  <AppTextField
                    name="title"
                    placeholder="게시판 주소 ex) forum"
                    value={boardPath}
                    onChange={(event) => {setBoardPath(event.target.value)}}
                    sx={{
                      backgroundColor: "#ffffff",
                      maxWidth: "450px",
                      width: "100%",
                      "& .MuiOutlinedInput-input::placeholder": {
                        fontWeight: 300,
                        fontSize: 15.5,
                        color: "#B5B7C0",
                      },
                    }}
                    inputProps={{ style: { height: 18 } }}
                  />
                </FlexRowAlign>
                {/* 게시판 접근 권한, 기본 권한(게시글 확인시)설정 */}
                <FlexRowAlign
                  item
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                >
                  <Grid item lg={3} xs={3} mr={3}>
                    <ButtonText mb={1}>게시판 접근 권한</ButtonText>
                    <Select
                      onChange={(e) => setMainBoardAccessUserType(e.target.value || "제한없음")}
                      IconComponent={() => <KeyboardArrowDown sx={{ color: "#B5B7C0" }} />}
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            padding: "0",
                            margin: "0",
                          },
                        },
                      }}
                      input={
                        <InputBase
                          value={mainBoardAccessUserType}
                          sx={{
                            height: 51,
                            width: "100%",
                            fontSize: 15.5,
                            borderRadius: "8px",
                            color: "text.primary",
                            backgroundColor: "#ffffff",
                            marginBottom: 0,
                            paddingLeft: 1,
                            paddingRight: 1,
                            border: "1px solid #E1E1E1",
                            "& .MuiPopover-paper": {
                              boxShadow: "none",
                            },
                          }}
                        />
                      }
                    >
                    {
                      Object.keys(userTypes).reverse().map((key) => {
                        return (
                          <MenuItem
                            sx={{
                              height: 51,
                              width: "100%",
                              fontSize: 14,
                              fontWeight: 300,
                              border: "1px solid #E1E1E1",
                            }}
                            value={key}
                          >
                            {key}
                          </MenuItem>
                        )
                      })
                    }
                    </Select>
                  </Grid>
                  <Grid item lg={3} xs={3}>
                    <ButtonText mb={1}>게시글 확인 권한</ButtonText>
                    <Select
                      onChange={(e) => setMainBoardDisplayUserType(e.target.value || "제한없음")}
                      IconComponent={() => <KeyboardArrowDown sx={{ color: "#B5B7C0" }} />}
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            padding: "0",
                            margin: "0",
                          },
                        },
                      }}
                      input={
                        <InputBase
                          value={mainBoarddisplayUserType}
                          sx={{
                            height: 51,
                            width: "100%",
                            fontSize: 15.5,
                            borderRadius: "8px",
                            color: "text.primary",
                            backgroundColor: "#ffffff",
                            marginBottom: 0,
                            paddingLeft: 1,
                            paddingRight: 1,
                            border: "1px solid #E1E1E1",
                            "& .MuiPopover-paper": {
                              boxShadow: "none",
                            },
                          }}
                        />
                      }
                    >
                    {
                      Object.keys(userTypes).reverse().map((key) => {
                        return (
                          <MenuItem
                            sx={{
                              height: 51,
                              width: "100%",
                              fontSize: 14,
                              fontWeight: 300,
                              border: "1px solid #E1E1E1",
                            }}
                            value={key}
                          >
                            {key}
                          </MenuItem>
                        )
                      })
                    }
                    </Select>
                  </Grid>
                </FlexRowAlign>
                <Grid item xs={12}>
                  <ButtonText mb={1}>배너 업로드</ButtonText>
                  <FlexRowAlign
                    sx={{ justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <Box
                      mr={2}
                      sx={{
                        width: "130px",
                        height: "60px",

                        background: bannerUrl
                          ? `url(${bannerUrl}) no-repeat center/cover`
                          : theme.palette.borderColor,

                        borderRadius: 3,
                      }}
                    />

                    <Box
                      px={1}
                      gap={0}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid",
                        borderColor: theme.palette.borderColor,
                        width: isMobile ? "100%" : "fit-content",
                        backgroundColor: "#ffffff",
                        minWidth: isMobile ? 0 : 382,
                        height: "50px",
                        borderRadius: 2,
                      }}
                    >
                      <Tiny
                        sx={{
                          fontWeight: 300,
                          color: theme.palette.primary.grey400,
                        }}
                      >
                        {bannerFile ? bannerFile.name : "선택된 파일 없음"}
                      </Tiny>
                      <input
                            ref={bannerFileRef}
                            onChange={handleBannerFileInputChange}
                            accept="image/*"
                            id="icon-file"
                            type="file"
                            style={{
                              width: "100%",
                            }}
                          />
                    </Box>
                  </FlexRowAlign>
                </Grid>

              </>
            )
          }
        </Grid>

        <Grid item xs={12}>
          <ButtonText mb={1}>버튼 하이퍼링크</ButtonText>
          <FlexRowAlign gap={2} sx={{ justifyContent: "flex-start" }}>
            <AppTextField
              name="buttonLink"
              placeholder="링크 입력"
              value={buttonLink}
              onChange={handleButtonLinkChange}
              sx={{
                backgroundColor: "#ffffff",
                maxWidth: "450px",
                width: "100%",
                "& .MuiOutlinedInput-input::placeholder": {
                  fontWeight: 300,
                  fontSize: 15.5,
                  color: "#B5B7C0",
                },
              }}
              inputProps={{ style: { height: 18 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Link color={theme.palette.primary.grey400} />
                  </InputAdornment>
                ),
              }}
            />
          </FlexRowAlign>
        </Grid>

        {
          item?.title && (
            <Grid item xs={12}>
          <ButtonText mb={1}>아이콘 업로드</ButtonText>
          <FlexRowAlign
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Box
              mr={2}
              sx={{
                width: "50px",
                height: "50px",

                background: iconUrl
                  ? `url(${iconUrl}) no-repeat center/cover`
                  : theme.palette.borderColor,

                borderRadius: 3,
              }}
            />

            <Box
              px={1}
              gap={0}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid",
                borderColor: theme.palette.borderColor,
                width: isMobile ? "100%" : "fit-content",
                backgroundColor: "#ffffff",
                minWidth: isMobile ? 0 : 382,
                height: "50px",
                borderRadius: 2,
              }}
            >
                  <input
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const formData = new FormData();
                      formData.append('file', file);
                      axios.post(process.env.REACT_APP_API_URI+'/v4/file/upload', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': 'Bearer ' + token
                        }
                      }).then((res) => {
                        const url = res.data.data.file_url
                        setIconUrl(url)
                      })
                      setIconFile(file);
                    }}
                    accept="image/*"
                    id="icon-file234"
                    type="file"
                    style={{
                      width: "100%",
                    }}
                  />
            </Box>
          </FlexRowAlign>
        </Grid>
          )
        }
      </Grid>
          </div>
        </Card>
      </Modal>
    </>
  );
}
