import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Card, Grid, useMediaQuery, Modal } from "@mui/material";
import logo from "assets/sugar_logo.png";
import { H2, H5, H6, H7 } from "components/Typography";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import IconX from "assets/icon-close-modal.svg";
import Lottie from "lottie-react";
import warningAnimation from "assets/warning-animation-lottie.json";
import FlexBox from "components/flexbox/FlexBox";

export default function WarningModalV2({ showModal, setShowModal, title, description, onAction }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "20%",
    maxHeight: "90vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid #E1E1E1",
    boxShadow: 24,
    paddingTop: isMobile ? 4 : 8,
    paddingBottom: isMobile ? 4 : 6,
    paddingLeft: isMobile ? 2 : 4,
    paddingRight: isMobile ? 2 : 4,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
  };

  const handleClose = () => {
    if( onAction ) onAction();
    setShowModal(false);
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {setShowModal(false)}}
        closeOnClick={false}
        disableEnforceFocus={true}
        disableBackdropClick={false} // Disable closing when clicking on the backdrop
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={style}>
              <img
                onClick={() => setShowModal(false)}
                src={IconX}
                style={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "22px",
                  right: "22px",
                }}
              />
              <FlexBox
                style={{
                  width: "100%",
                  height: 200,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FlexBox
                  style={{
                    width: 200,
                    height: 200,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Lottie animationData={warningAnimation} loop={true} />
                </FlexBox>
              </FlexBox>
              <Box
                mt={0}
                mb={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <H2 style={{ fontWeight: "bold" }}>{title}</H2>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <H7
                    mb={1}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {description}
                  </H7>
                </Grid>

                <Grid
                  ml={0}
                  mt={1}
                  container
                  xs={12}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <Grid item width="100%">
                    <Button
                      onClick={() => {
                        handleClose()
                      }}
                      // type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        fontSize: 15.5,
                        fontWeight: 500,
                        minWidth: "100%",
                        width: "100%",
                        borderRadius: 2,
                        backgroundColor: "primary.darkBlue",
                        "&:hover": {
                          backgroundColor: "primary.darkBlueHover",
                        },
                      }}
                    >
                      확인
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  ml={0}
                  mt={1}
                  container
                  xs={12}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <Grid item width="100%">
                    <Button
                      onClick={() => {
                        setShowModal(false)
                      }}
                      // type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        fontSize: 15.5,
                        fontWeight: 500,
                        minWidth: "100%",
                        width: "100%",
                        borderRadius: 2,
                        backgroundColor: "primary.darkBlue",
                        "&:hover": {
                          backgroundColor: "primary.darkBlueHover",
                        },
                      }}
                    >
                      취소
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}