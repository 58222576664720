import React from "react";
import { Box, Button, Card, styled } from "@mui/material";
import { ButtonText, H5, H6, Paragraph, Small } from "components/Typography";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
// ----------------------------------
import naver from "assets/fab-naver.png";
import kakao from "assets/fab-kakao.png";
import insta from "assets/fab-insta.png";
import youtube from "assets/fab-youtube.png";
import blog from "assets/naver-blog.svg";
import { useSelector } from "react-redux";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import { accountPermissionCompare } from "utils/compareAccount";
import { useState } from "react";
import QModal from "page/modal/q-modal";
import { setSetting } from "reducer/sidebarReducer";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Lottie from "lottie-react";
import EditMenuModal from "./modal/editMenuModal";
import EditFooterModal from "./modal/EditFooterModal";
import SurveyJdoctor from "page/modal/survey-jdoctor";
import SurveyModal from "page/modal/survey-modal2";
import { CustomModal } from "page/modal/terms-modal";
// ----------------------------------

const Footer = ({ showSideBar }) => {
  const [open, setOpen] = useState(false);
  const setting = useSelector((state) => state.Sidebar.setting);
  const account = useSelector((state) => state.Auth.account);
  const token = useSelector((state) => state.Auth.token);
  const dispatch = useDispatch()
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();

  const [openQModal, setOpenQModal] = useState(false);

  const [footer, setFooter] = useState();

  const [footerData, setFooterData] = useState(setting?.footer_nav || [
    [
      {
        "title": "공지사항",
        "link": "/board/notice"
      },
      {
        "title": "서비스 이용약관"
      }
    ],
    [
      {
        "title": "개인정보처리방침"
      }
    ],
    [
      {
        "title": "네이버 블로그",
        "icon": "fab-naver.png"
      },
      {
        "title": "유튜브",
        "icon": "fab-youtube.png"
      }
    ],
    [
      {
        "title": "카카오톡",
        "icon": "fab-kakao.png"
      },
      {
        "title": "인스타그램",
        "icon": "fab-insta.png"
      }
    ]
  ]);

  useEffect(() => {
    let at = setting?.footer_nav || [
      [
        {
          "title": "공지사항",
          "link": "/board/notice"
        },
        {
          "title": "서비스 이용약관"
        }
      ],
      [
        {
          "title": "개인정보처리방침"
        }
      ],
      [
        {
          "title": "네이버 블로그",
          "icon": "fab-naver.png"
        },
        {
          "title": "유튜브",
          "icon": "fab-youtube.png"
        }
      ],
      [
        {
          "title": "카카오톡",
          "icon": "fab-kakao.png"
        },
        {
          "title": "인스타그램",
          "icon": "fab-insta.png"
        }
      ]
    ]
    setFooterData(at)

  }, [setting])


  const location = useLocation();
  let sidebarWidth = "230PX";

  // switch (location.pathname) {
  //   case "/naver/jungbaksa":
  //   case "/dashboard/course":
  //   case "/dashboard/forumdetail":
  //   case "/dashboard/forumlist":
  //   case "/dashboard/calendar":
  //   case "/dashboard/namecardprofile":
  //   case "/dashboard/namecard":
  //   case "/dashboard/myinfoedit":
  //   case "/dashboard/statistics":
  //     sidebarWidth = "230px";

  //     break;
  //   default:
  //     sidebarWidth = "260px";
  // }

  // styled components
  const StyledCard = styled(Card)(({ theme, showSideBar }) => ({
    width: `calc(100% - ${isTablet ? "0px" : showSideBar ? sidebarWidth : "0px"
      })`,
    marginLeft: isTablet ? "0px" : showSideBar ? sidebarWidth : "0px",

    // marginTop: "1rem",
    backgroundColor: "#EAEAEA",
    borderRadius: 0,
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("sm")]: {
      "& > .MuiBox-root": {
        paddingBottom: 0,
      },
    },
  }));



  const [item, setItem] = useState(null);

  const [addItem, setAddItem] = useState(null);

  const OpenAddModal = (i) => {
    setAddItem({
      index: i,
      info: {}
    })
    setOpenQModal(true)
  }

  const OpenFixModal = (i, j) => {
    setItem({
      index: i,
      index2: j,
      info: footerData[i][j]
    })
    setOpenQModal(true)
  }

  const OpenQeustionAlert = () => {
    const data = window.prompt("FOOTER 수정", setting?.footer);
    if (!data) return;
    const newSetting = { ...setting }
    newSetting.footer = data
    axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      dispatch(setSetting(newSetting))
    })
  }
  const isInsurance = window.location.hostname == 'xn--3j1bu51b3tg.com' || window.location.hostname == 'sugar.ceo' ? true : false

  const [showSurveyModal, setShowSurveyModal] = useState(false);
  return (
    <StyledCard showSideBar={showSideBar}>

      <CustomModal open={open} setOpen={setOpen} onClose={() => {
        setOpen(false)
      }} />
      {isInsurance ? <SurveyJdoctor open={showSurveyModal} setShowModal={setShowSurveyModal} /> : <SurveyModal open={showSurveyModal} setShowModal={setShowSurveyModal} />}
      <Toaster />
      {
        item && openQModal && <EditFooterModal item={item.info} open={openQModal} setShowModal={setOpenQModal} onAction={(e) => {
          const newSetting = { ...setting }
          if (!newSetting?.footer_nav) {
            newSetting.footer_nav = footerData
          }
          newSetting.footer_nav[item.index][item.index2] = e
          axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }).then(res => {
            dispatch(setSetting(newSetting))
            setItem(null)
            setOpenQModal(false)
            toast.success("푸터 수정 완료")
          })
        }} />
      }
      {
        addItem && openQModal && <EditFooterModal item={addItem.info} open={openQModal} setShowModal={setOpenQModal} onAction={(e) => {
          if (!e.title) {
            toast.error("제목을 입력해주세요")
            return
          }
          const newSetting = { ...setting }
          if (!newSetting?.footer_nav) {
            newSetting.footer_nav = footerData
          }
          newSetting.footer_nav[addItem.index].push(e)
          axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }).then(res => {
            dispatch(setSetting(newSetting))
            setAddItem(null)
            setOpenQModal(false)
            toast.success("푸터 추가 완료")
          })
        }} />
      }
      <Grid
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: { xs: "2rem 2rem", lg: "2rem 4rem" },
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        {/* first and second menu */}
        <Grid
          xs={12}
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: { xs: "row", lg: "row" },
            marginBottom: { xs: 4, lg: 0 },
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {footerData[0]?.concat(
              accountPermissionCompare(account?.type, "마스터") && footerData[0]?.length < 4 ? [{ title: "추가" }] : []
            ).map((item, index) => {
              if (item.title === "추가") {
                return (
                  <>
                    <ContextMenuTrigger id={"footer1-" + index} key={1}>
                      <Grid item key={index} onClick={
                        () => {
                          OpenAddModal(0, index)
                        }
                      }>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                  </>
                )
              }

              if (accountPermissionCompare(account?.type, "마스터")) {
                return (
                  <>
                    <ContextMenuTrigger id={"footer1-" + index} key={1}>
                      <Grid item key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                    <ContextMenu id={"footer1-" + index}>
                      <ContextMenuItem onClick={() => {
                        OpenFixModal(0, index)
                      }}>푸터 수정</ContextMenuItem>
                      <ContextMenuItem onClick={() => {
                        const newSetting = { ...setting }
                        newSetting.footer_nav[0].splice(index, 1)
                        axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          }
                        }).then(res => {
                          dispatch(setSetting(newSetting))
                        })
                      }}>삭제</ContextMenuItem>
                    </ContextMenu>
                  </>
                )
              }
              return (
                <Grid item key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (item?.title == "개인정보처리방침") {
                        setOpen(true)
                      }
                    }}
                  >
                    {item?.icon && !item?.icon?.includes('.png') && <Lottie
                      animationData={require(`assets/icon/${item?.icon}`)}
                      loop={true}
                      autoplay={true}
                      style={{
                        width: 20, marginRight: "4px", objectFit: "contain"
                      }}
                    />}
                    {
                      item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                        marginRight: "4px", objectFit: "contain"
                      }} />
                    }
                    <H6>
                      <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                        {item.title}
                      </NavLink>
                    </H6>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {footerData[1]?.concat(
              accountPermissionCompare(account?.type, "마스터") && footerData[1]?.length < 4 ? [{ title: "추가" }] : []
            ).map((item, index) => {
              if (item.title === "추가") {
                return (
                  <>
                    <ContextMenuTrigger id={"footer1-" + index} key={1}>
                      <Grid item key={index} onClick={
                        () => {
                          OpenAddModal(1, index)
                        }
                      }>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                  </>
                )
              }
              if (accountPermissionCompare(account?.type, "마스터")) {
                return (
                  <>
                    <ContextMenuTrigger id={"footer2-" + index} key={1}>
                      <Grid item key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                    <ContextMenu id={"footer2-" + index}>
                      <ContextMenuItem onClick={() => {
                        OpenFixModal(1, index)
                      }}>푸터 수정</ContextMenuItem>
                      <ContextMenuItem onClick={() => {
                        const newSetting = { ...setting }
                        newSetting.footer_nav[1].splice(index, 1)
                        axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          }
                        }).then(res => {
                          dispatch(setSetting(newSetting))
                        })
                      }}>삭제</ContextMenuItem>
                    </ContextMenu>
                  </>
                )
              }
              return (
                <Grid item key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (item?.title == "개인정보처리방침") {
                        setOpen(true)
                      }
                    }}
                  >
                    {item?.icon && !item?.icon?.includes('.png') && <Lottie
                      animationData={require(`assets/icon/${item?.icon}`)}
                      loop={true}
                      autoplay={true}
                      style={{
                        width: 20, marginRight: "4px", objectFit: "contain"
                      }}
                    />}
                    {
                      item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                        marginRight: "4px", objectFit: "contain"
                      }} />
                    }
                    <H6>
                      <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                        {item.title}
                      </NavLink>
                    </H6>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        {/* third and fourth menu */}
        <Grid
          xs={12}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: { xs: "row", lg: "row" },
            marginBottom: { xs: 4, lg: 0 },
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {footerData[2]?.concat(
              accountPermissionCompare(account?.type, "마스터") && footerData[2]?.length < 4 ? [{ title: "추가" }] : []
            ).map((item, index) => {
              if (item.title === "추가") {
                return (
                  <>
                    <ContextMenuTrigger id={"footer1-" + index} key={1}>
                      <Grid item key={index} onClick={
                        () => {
                          OpenAddModal(2, index)
                        }
                      }>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                  </>
                )
              }
              if (accountPermissionCompare(account?.type, "마스터")) {
                return (
                  <>
                    <ContextMenuTrigger id={"footer3-" + index} key={1}>
                      <Grid item key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                    <ContextMenu id={"footer3-" + index}>
                      <ContextMenuItem onClick={() => {
                        OpenFixModal(2, index)
                      }}>푸터 수정</ContextMenuItem>
                      <ContextMenuItem onClick={() => {
                        const newSetting = { ...setting }
                        newSetting.footer_nav[2].splice(index, 1)
                        axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          }
                        }).then(res => {
                          dispatch(setSetting(newSetting))
                        })
                      }}>삭제</ContextMenuItem>
                    </ContextMenu>
                  </>
                )
              }
              return (
                <Grid item key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (item?.title == "개인정보처리방침") {
                        setOpen(true)
                      }
                    }}
                  >
                    {item?.icon && !item?.icon?.includes('.png') && <Lottie
                      animationData={require(`assets/icon/${item?.icon}`)}
                      loop={true}
                      autoplay={true}
                      style={{
                        width: 20, marginRight: "4px", objectFit: "contain"
                      }}
                    />}
                    {
                      item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                        marginRight: "4px", objectFit: "contain"
                      }} />
                    }
                    <H6>
                      <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                        {item.title}
                      </NavLink>
                    </H6>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {footerData[3]?.concat(
              accountPermissionCompare(account?.type, "마스터") && footerData[3]?.length < 4 ? [{ title: "추가" }] : []
            ).map((item, index) => {
              if (item.title === "추가") {
                return (
                  <>
                    <ContextMenuTrigger id={"footer1-" + index} key={1}>
                      <Grid item key={index} onClick={
                        () => {
                          OpenAddModal(3, index)
                        }
                      }>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                  </>
                )
              }
              if (accountPermissionCompare(account?.type, "마스터")) {
                return (
                  <>
                    <ContextMenuTrigger id={"footer4-" + index} key={1}>
                      <Grid item key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (item?.title == "개인정보처리방침") {
                              setOpen(true)
                            }
                          }}
                        >
                          {item?.icon && !item?.icon?.includes('.png') && <Lottie
                            animationData={require(`assets/icon/${item?.icon}`)}
                            loop={true}
                            autoplay={true}
                            style={{
                              width: 20, marginRight: "4px", objectFit: "contain"
                            }}
                          />}
                          {
                            item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                              marginRight: "4px", objectFit: "contain"
                            }} />
                          }
                          <H6>
                            <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                              {item.title}
                            </NavLink>
                          </H6>
                        </Box>
                      </Grid>
                    </ContextMenuTrigger>
                    <ContextMenu id={"footer4-" + index}>
                      <ContextMenuItem onClick={() => {
                        OpenFixModal(3, index)
                      }}>푸터 수정</ContextMenuItem>
                      <ContextMenuItem onClick={() => {
                        const newSetting = { ...setting }
                        newSetting.footer_nav[3].splice(index, 1)
                        axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          }
                        }).then(res => {
                          dispatch(setSetting(newSetting))
                        })
                      }}>삭제</ContextMenuItem>
                    </ContextMenu>
                  </>
                )
              }
              return (
                <Grid item key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (item?.title == "개인정보처리방침") {
                        setOpen(true)
                      }
                    }}
                  >
                    {item?.icon && !item?.icon?.includes('.png') && <Lottie
                      animationData={require(`assets/icon/${item?.icon}`)}
                      loop={true}
                      autoplay={true}
                      style={{
                        width: 20, marginRight: "4px", objectFit: "contain"
                      }}
                    />}
                    {
                      item?.icon && item?.icon?.includes('.png') && <img src={require(`assets/icon/${item?.icon}`)} alt={"icon"} width={20} style={{
                        marginRight: "4px", objectFit: "contain"
                      }} />
                    }
                    <H6>
                      <NavLink to={`${item.link || "#"}`} style={{ color: "#151515" }}>
                        {item.title}
                      </NavLink>
                    </H6>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        <Grid
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ minWidth: isTablet ? "inherit" : 350, width: "100%" }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {/* <Box> */}
            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: 2,
                width: "100%",
                borderRadius: 2,
                background: theme.palette.gradient.purpletoblue,
              }}
              onClick={() => {
                setShowSurveyModal(true)
              }}
            >
              <ButtonText>문의하기</ButtonText>
            </Button>
            {/* <Small> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: isTablet ? "column" : "row",
                justifyContent: "center",
              }}
            >
              <Small
                style={{
                  textAlign: "center",
                  color: "#151515",
                  fontWeight: 400,
                  marginRight: "6px",
                }}
              >
                {t("평일 10:00 - 18:00")}
              </Small>
              {/* </Small> */}
              <Small>{t("점심시간 12 - 13시 | 주말 및 공휴일 제외")}</Small>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          padding: { xs: "0rem 2rem", lg: "0rem 4rem" },
        }}
      >
        <Box
          sx={{
            borderTop: "1px solid #CBCBCB",
            padding: "1rem 0rem 2rem 0rem",
          }}
        >
          <Small lineHeight={1} sx={{ lineHeight: 1 }}>
            {
              accountPermissionCompare(account?.type, "마스터") && (
                <>
                  <ContextMenuTrigger id="footer" key={1}>
                    {setting?.footer
                      ?.split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </ContextMenuTrigger>
                  <ContextMenu id="footer">
                    <ContextMenuItem onClick={() => {
                      OpenQeustionAlert()
                    }}>푸터 수정</ContextMenuItem>
                  </ContextMenu>
                </>
              )
            }

            {!accountPermissionCompare(account?.type, "마스터") && setting?.footer
              ?.split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </Small>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default Footer;
