import { Fragment, useState } from "react";
import { Outlet, useLocation } from "react-router"; // Import useLocation hook
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import Footer from "components/Footer";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import BottomBar from "components/BottomBar";
import FAB from "components/FAB";
import DashboardHeaderNew from "layouts/layout-parts/DashboardHeader-new";
import InsuranceHeader from "layouts/layout-parts/InsuranceHeader";

const NaverLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation(); // Get the current location

  const [showSideBar, setShowSideBar] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  // Check if the current route is '/naver/wrtn'
  const hideFooter = location.pathname.includes("/profile/") || location.pathname.includes("/cretop") || location.pathname.includes('/course/buy');

  const isInsurance = location.pathname.startsWith("/manage");

  return (
    <Fragment>
      {
        isInsurance ? (
          <InsuranceHeader/>
        ) : (
            <DashboardHeaderNew
              setShowSideBar={() => setShowSideBar((state) => !state)}
              setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
            />
        )
      }
      <FAB marginBottom={"60px"} />
      {isMobile && window.location.hostname != "booksense.club" && (
        <BottomBar setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)} showMobileSideBar={showMobileSideBar} />
      )}
      {/* Conditionally render children or Outlet based on route */}
      {hideFooter || window.location.hostname == "booksense.club" ? (
        children || <Outlet />
      ) : (
        <Fragment>
          {children || <Outlet />}
          <Footer showSideBar={showSideBar} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default NaverLayout;
